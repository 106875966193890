import React from 'react';
import { useField } from 'formik';
import { FormControlLabel, Checkbox as MuiCheckbox, Switch as MuiSwitch } from '@material-ui/core';

export function Checkbox(props) {
  const [field] = useField(props)
  const { label, labelPlacement, ...checkBoxProps } = props;

  return (
    <FormControlLabel
      control={<MuiCheckbox {...checkBoxProps} {...field} checked={field.value} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export function Switch(props) {
  const [field] = useField(props);
  const { label, labelPlacement, ...switchProps } = props;

  return (
    <FormControlLabel
      control={<MuiSwitch {...switchProps} {...field} checked={field.value} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}