import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateLength } from 'helpers';
import { Typography } from '@material-ui/core';
import DireccionForm, { validateDireccion } from 'components/direccion/DireccionForm';

function CentroAsistencialForm ({ item, error, onSubmit }) {

  const initialValues = {
    nombre: item ? item.nombre : '',
    telefono: item ? item.telefono : '',
    direccion: {
      idTipo: item ? item.direccion.idTipo.toString() : '1',
      calle: item ? item.direccion.calle : '',
      numero: item ? item.direccion.numero : '',
      villa: item ? item.direccion.villa : '',
      otro: item ? item.direccion.otro : '',
      comuna: item ? item.direccion.sector.comuna : null,
      sector: item ? item.direccion.sector : null,
      idSector: item ? item.direccion.idSector : '',
    },
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { direccion: { sector, comuna, ...direccionInput }, ...itemValues } = values;
    itemValues.direccion = direccionInput;

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ values, dirty, handleSubmit }) => (
        <Form alignItems="left">
          <Form.Field xs={12} md={8}>
            <Form.TextField required name="nombre" label="Nombre" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.TextField required name="telefono" label="Teléfono" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Dirección</Typography>
          </Form.Field>

          <DireccionForm values={values.direccion} path="direccion" />
          
          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = 'El nombre es obligatorio'
  }
  if (!values.telefono) {
    errors.telefono = 'El teléfono es obligatorio'
  }
  const direccionErrors = validateDireccion(values.direccion);
  if (direccionErrors) {
    errors.direccion = direccionErrors;
  }
  
  return errors;
}

CentroAsistencialForm.propTypes = {
  item: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default CentroAsistencialForm;