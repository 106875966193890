import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core';
import { Loader, Snackbar, Breadcrumbs } from 'components/ui';
import PacienteForm from 'components/paciente/PacienteForm';
import ListadoFichas from 'components/ficha/ListadoFichas';
import ListadoContactos from 'components/paciente/ListadoContactos';
import { hasPermission, useAuth } from 'auth';
import { useMutation, useQuery } from 'api';
import { formatPaciente } from '../../helpers';
import { PERMISO } from '../../constants';

function EditarPaciente({ id }) {
  const auth = useAuth();
  const [snackOpen, setSnackOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const { data: paciente, loading, refetch } = useQuery(`/pacientes/${id}`);
  const [editarPaciente, { loading: posting, error }] = useMutation(`/pacientes/${id}`, { method: 'PATCH' });

  const handleSubmit = async values => {
    const { error } = await editarPaciente({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  useEffect(() => {
    refetch();
    setActiveTab(0);
  }, [id]);

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'pacientes', title: 'Pacientes', to: '/admin/pacientes' },
        { key: 'actual', title: paciente ? formatPaciente(paciente) : id, to: `/admin/pacientes/${id}`, active: true },
      ]} />
      <Loader loading={loading || posting} />

      <Tabs
        value={activeTab}
        variant="fullWidth"
        onChange={(e, newTab) => setActiveTab(newTab)}
      >
        <Tab label="Datos Personales" />
        {auth.hasPermission([PERMISO.FichasVer]) &&
          <Tab label="Fichas Médicas" />}
        {auth.hasPermission([PERMISO.ContactosVer]) &&
          <Tab label="Contacto Estrecho" />}
      </Tabs>

      {paciente && activeTab === 0 &&
        <PacienteForm
          paciente={paciente}
          error={error}
          onSubmit={handleSubmit}
        />
      }
      {paciente && activeTab === 1 &&
        <ListadoFichas
          paciente={paciente}
        />
      }
      {paciente && activeTab === 2 &&
        <ListadoContactos
          paciente={paciente}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.PacientesModificar])(EditarPaciente);
