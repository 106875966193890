import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export function ConfirmDialog({ children, title, text, confirmText, cancelText, onConfirm, onCancel, confirmOnly }) {
  const [open, setOpen] = useState(false);
  
  const openModal = () => setOpen(true);
  
  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) { onConfirm(); }
  }

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) { onCancel(); }
  }

  return (
    <React.Fragment>
      {children(openModal)}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent dividers>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
        {!confirmOnly && 
          <Button onClick={handleCancel} color="primary">
            {cancelText}
          </Button>
        }
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmText}
        </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

ConfirmDialog.propTypes = {
  children: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  confirmOnly: PropTypes.bool,
}

ConfirmDialog.defaultProps = {
  confirmText: 'Confirmar',
  cancelText: 'Cancelar',
  confirmOnly: false,
};
