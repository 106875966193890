import React, { useState } from 'react';
import { Button, TableRow, TableCell, Box } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { DataTable, Loader, CompactTableCell, ActionsButton, Link } from 'components/ui';
import { useMutation, useQuery } from 'api';
import { formatDate, formatPaciente } from 'helpers/format';
import { PERMISO, TIPO_CONTACTO } from '../../constants';
import ContactoModal from 'components/paciente/ContactoModal';

function ListadoContactos({ paciente }) {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  
  const canEdit = auth.hasPermission([PERMISO.ContactosModificar]);

  const { data: contactos, loading, refetch } = useQuery(`/pacientes/${paciente.id}/contactos`);
  const [crearContacto] = useMutation(`/pacientes/${paciente.id}/contactos`, { method: 'post' })
  const [editarContacto] = useMutation(`/pacientes/${paciente.id}/contactos/${modalData?.id}`, { method: 'patch' })

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });

  const openModal = (contacto) => {
    setModalData(contacto);
    setModalOpen(true);
  }
  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  }

  const handleSubmit = async values => {
    let resp;
    if (modalData) {
      resp = await editarContacto({ body: values });
    } else {
      resp = await crearContacto({ body: values });
    }
  
    if (!resp.error) {
      refetch();
      closeModal();
    }
  }
  
  return (
    <div>
      <Loader loading={loading} />

      <DataTable
        data={contactos}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ContactoHeader />}
        renderRow={(contacto) => (
          <ContactoRow
            key={contacto.id}
            paciente={paciente}
            contacto={contacto}
            canEdit={canEdit}
            onEdit={() => openModal(contacto)}
          />
        )}
      />

      {canEdit && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            align="right"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => openModal(null)}
          >
            Agregar
          </Button>
        </Box>
      )}

      <ContactoModal
        paciente={paciente}
        contacto={modalData}
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
      />

    </div>
  );
}

function ContactoHeader() {
  return (
    <TableRow>
      <TableCell>#</TableCell>
      <TableCell>Nombre Completo</TableCell>
      <TableCell>Fecha</TableCell>
      <TableCell>Lugar</TableCell>
      <TableCell>Relación</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function ContactoRow({ paciente, contacto, canEdit, onEdit }) {
  const contactado = contacto.idPacienteOrigen === paciente.id ? contacto.pacienteDestino : contacto.pacienteOrigen;

  return (
    <TableRow>
      <CompactTableCell>{contacto.id}</CompactTableCell>
      <CompactTableCell>
        <Link to={`/admin/pacientes/${contactado.id}`} color="textPrimary">
          {formatPaciente(contactado)}
        </Link>
      </CompactTableCell>
      <CompactTableCell>{formatDate(contacto.fechaContacto)}</CompactTableCell>
      <CompactTableCell>{contacto.lugar}</CompactTableCell>
      <CompactTableCell>{TIPO_CONTACTO[contacto.tipo]}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: onEdit }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.ContactosVer])(ListadoContactos);
