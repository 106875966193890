import React from 'react';
import Admin from 'components/admin';
import TipoAyudaForm from 'components/listados/TipoAyudaForm';
import { PERMISO } from '../../constants';

export default function TipoAyudaPages() {
  return (
    <Admin
      createTitle="Nuevo Tipo de Ayuda"
      listTitle="Tipos de Ayuda"
      editTitle="Editar Tipo de Ayuda"
      routePath="/admin/tipos-ayuda"
      apiPath="/tipos-ayuda"
      FormComponent={TipoAyudaForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
