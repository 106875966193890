import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';
import { TextField as MuiTextField, PasswordField as MuiPasswordField } from '../TextField';

export function TextField (props) {
  const [field, meta] = useField(props)
  const hasError = meta.touched && !!meta.error;
  const textProps = _.omit(props, ['validate'])

  return (
    <MuiTextField
      {...field}
      {...textProps}
      value={field.value || ''}
      error={hasError}
      helperText={hasError && meta.error}
    />
  );
}

export function PasswordField (props) {
  const [field, meta] = useField(props)
  const hasError = meta.touched && !!meta.error;
  const textProps = _.omit(props, ['validate'])

  return (
    <MuiPasswordField
      {...field}
      {...textProps}
      id={field.name}
      value={field.value || ''}
      error={hasError}
      helperText={hasError && meta.error}
    />
  );
}