import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon, Edit, Check, Clear } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, ActionsButton, Breadcrumbs, Title } from 'components/ui';
import { useQuery } from 'api';
import { navigate } from '@reach/router';
import { PERMISO } from '../../constants';

function ListadoRoles() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  
  const canEdit = auth.hasPermission([PERMISO.RolesModificar]);

  const { data: roles, loading, refetch } = useQuery('/roles', { query: state });
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'roles', title: 'Roles', to: '/admin/roles', active: true },
      ]} />
      <Title>Roles</Title>
      <Loader loading={loading} />
      
      <DataTable
        data={roles}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <RolHeader />}
        renderRow={(rol) => (
          <RolRow
            key={rol.id}
            rol={rol}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/roles/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function RolHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Activo</TableCell>
      <TableCell>Editable</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function RolRow({ rol, canEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{rol.id}</CompactTableCell>
      <CompactTableCell>{rol.nombre}</CompactTableCell>
      <CompactTableCell>{rol.vigente ? <Check color='primary'/> : <Clear color='error' />}</CompactTableCell>
      <CompactTableCell>{rol.editable ? <Check color='primary'/> : <Clear color='error' />}</CompactTableCell>
      <CompactTableCell>
        {(canEdit && rol.editable) && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/roles/${rol.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.RolesVer])(ListadoRoles);
