import React from 'react';
import { styled, Grid, Button } from '@material-ui/core';
import { Form as FormikForm } from 'formik';

const StyledForm = styled(FormikForm)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const Form = ({ children, ...props }) => (
  <StyledForm>
    <Grid container spacing={3} justify="space-around" {...props}>
      {children}
    </Grid>
  </StyledForm>
);

Form.Field = (props) => <Grid item {...props} />;

Form.Actions = ({ children}) => (
  <Grid item xs={12} container justify="flex-end">
    <Grid item>{ children }</Grid>
  </Grid>
);

Form.Button = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}))
