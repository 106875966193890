import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateLength } from 'helpers';
import DireccionForm, { validateDireccion } from 'components/direccion/DireccionForm';

function ResidenciaSanitariaForm ({ item: residencia, error, onSubmit }) {
  const initialValues = {
    id: residencia ? residencia.id : '',
    nombre: residencia ? residencia.nombre : '',
    comuna: residencia ? residencia.comuna : null,
    direccion: {
      idTipo: residencia ? residencia.direccion.idTipo.toString() : '1',
      calle: residencia ? residencia.direccion.calle : '',
      numero: residencia ? residencia.direccion.numero : '',
      villa: residencia ? residencia.direccion.villa : '',
      otro: residencia ? residencia.direccion.otro : '',
      comuna: residencia ? residencia.direccion.sector.comuna : null,
      sector: residencia ? residencia.direccion.sector : null,
      idSector: residencia ? residencia.direccion.idSector : '',
    },
    telefono: residencia ? residencia.telefono : '',
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { direccion: { sector, comuna, ...direccionInput }, ...residenciaValues } = values;
    residenciaValues.direccion = direccionInput;

    onSubmit(residenciaValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, errors, dirty, handleSubmit }) => (
        <Form alignItems="center">

          <Form.Field xs={12} md={5}>
            <Form.TextField required name="nombre" label="Nombre" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="telefono" label="Teléfono" fluid validate={validateLength(0, 20)} />
          </Form.Field>

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Dirección</Typography>
          </Form.Field>
          
          <DireccionForm values={values.direccion} path="direccion" />

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = 'El nombre es obligatorio';
  }
  if (!values.telefono) {
    errors.telefono = 'El teléfono es obligatorio';
  }
  const direccionErrors = validateDireccion(values.direccion);
  if (direccionErrors) {
    errors.direccion = direccionErrors;
  }
  return errors;
}

ResidenciaSanitariaForm.propTypes = {
  residencia: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ResidenciaSanitariaForm;