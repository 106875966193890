import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: theme.spacing(6),
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  item: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
  icon: {
    color: theme.palette.primary.contrastText,
  }
}));

function SidebarMenu({ location, items }) {
  const classes = useStyles();
  const isActive = (item) => item.to === '/' ? location.pathname === item.to : location.pathname.startsWith(item.to);
  
  const initialIndex = items.findIndex((item) =>
    isActive(item) || (item.items && item.items.some((subitem) => isActive(subitem))))
  const [expanded, setExpanded] = React.useState(initialIndex);

  const handleMenuClick = (item, idx) => {
    if (item.items) {
      setExpanded(idx !== expanded ? idx : -1);
    } else if(item.to) {
      navigate(item.to);
    } else if (item.onClick) {
      item.onClick();
    }
  }

  const handleSubMenuClick = (item) => {
    if (item.to) {
      navigate(item.to);
    } else if(item.onClick) {
      item.onClick();
    }
  }

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {items.map((item, idx) => (
        <React.Fragment key={item.key}>
          <ListItem
            button
            selected={isActive(item)}
            disableRipple={isActive(item)}
            className={classes.item}
            onClick={() => handleMenuClick(item, idx)}
          >
            {item.icon && <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.text} />
            {item.items && (expanded === idx ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          {item.items &&
            <Collapse in={expanded === idx} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.items.map((subitem) => (
                  <ListItem
                    key={subitem.key}
                    button
                    selected={isActive(subitem)}
                    disableRipple={isActive(subitem)}
                    className={classes.nested}
                    onClick={() => handleSubMenuClick(subitem)}
                  >
                    {subitem.icon && <ListItemIcon className={classes.icon}>{subitem.icon}</ListItemIcon>}
                    <ListItemText primary={subitem.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          }
        </React.Fragment>
      ))}
    </List>
  )
}

const menuShape = {
  key: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

SidebarMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    ...menuShape,
    items: PropTypes.arrayOf(PropTypes.shape(menuShape)),
  }))
};

export default SidebarMenu;
