import React, { useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { useLazyQuery, useQuery } from 'api';
import { Form } from 'components/ui';
import { validateLength } from 'helpers';

function DireccionForm({ values, path }) {
  const { setFieldValue, setFieldTouched, touched } = useFormikContext();
  const { data: tiposDireccion } = useQuery('/tipos_direccion');
  const [buscarComunas] = useLazyQuery('/comunas');
  const [buscarSectores, sectoresQuery] = useLazyQuery('/sectores');
  const sectores = sectoresQuery.data || (values.sector ? [values.sector] : []);

  useEffect(() => {
    if (values.comuna) {
      if (touched[path]?.comuna) {
        setFieldValue(`${path}.idSector`, '');
        setFieldTouched(`${path}.idSector`);
      }
      buscarSectores({ query: { idComuna: values.comuna.id } });
    }
  }, [values.comuna]);

  return (
    <>
      <Form.Field xs={12} md={3}>
        <Form.Select required name={`${path}.idTipo`} label="Área" fluid align="left">
          {(tiposDireccion || []).map(td => <MenuItem key={td.id} value={td.id}>{td.nombre}</MenuItem>)}
        </Form.Select>
      </Form.Field>
      {values.idTipo.toString() === '1' ? (
        <>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name={`${path}.calle`} label="Calle" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name={`${path}.numero`} label="Número" fluid validate={validateLength(0, 10)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name={`${path}.villa`} label="Villa/Población" fluid validate={validateLength(0, 100)} />
          </Form.Field>
        </>
      ) : (
        <>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name={`${path}.calle`} label="Calle" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name={`${path}.villa`} label="Comunidad" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name={`${path}.otro`} label="Otro" fluid validate={validateLength(0, 100)} />
          </Form.Field>
        </>
      )}
      <Form.Field xs={12} md={3}>
        <Form.Lookup
          required
          name={`${path}.comuna`}
          label="Comuna"
          fluid
          groupBy={(option) => option.region.nombre}
          getOptionSelected={(option, value) => option.id === value?.id}
          getOptionLabel={(option) => option ? option.nombre : ''}
          fetcher={(busqueda) => buscarComunas({ query: { busqueda }}).then(resp => resp.data)}
        />
      </Form.Field>
      <Form.Field xs={12} md={3}>
        <Form.Select
          required
          name={`${path}.idSector`}
          label="Sector"
          fluid
          align="left"
        >
          {sectores.map(sector => (
            <MenuItem key={sector.id} value={sector.id}>{sector.nombre}</MenuItem>
          ))}
        </Form.Select>
      </Form.Field>
    </>
  )
}

export function validateDireccion(values) {
  const errors = {};
  
  if (!values.calle) {
    errors.calle = 'La calle es obligatoria';
  }
  if (!values.idSector) {
    errors.idSector = 'El sector es obligatorio';
  }
  const idTipoDireccion = values.idTipo.toString();
  if (idTipoDireccion === '1' && !values.numero) {
    errors.numero = 'El número es obligatorio';
  }

  return isEmpty(errors) ? null : errors;
}

export default DireccionForm;
