import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import FichaForm from 'components/ficha/FichaForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';
import { formatPaciente } from 'helpers';

function CrearFicha({ id }) {
  const { data: paciente, loading } = useQuery(`/pacientes/${id}`);
  const [crearFicha, { loading: posting, error }] = useMutation('/fichas', { method: 'post' });

  const handleSubmit = async values => {
    const { data, error } = await crearFicha({ body: values });

    if (!error) {
      navigate(`/admin/pacientes/${id}/fichas/${data.id}`);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'pacientes', title: 'Pacientes', to: '/admin/pacientes' },
        { key: 'paciente', title: paciente ? formatPaciente(paciente) : id, to: `/admin/pacientes/${id}` },
        { key: 'actual', title: 'Nueva Ficha', to: `/admin/pacientes/${id}/fichas/nuevo`, active: true },
      ]} />
      <Title>Nueva Ficha</Title>
      <Loader loading={loading || posting} />

      {paciente && (
        <FichaForm
          paciente={paciente}
          error={error}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default hasPermission([PERMISO.FichasModificar])(CrearFicha);
