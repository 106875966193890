import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { max } from 'lodash';
import { Title, ChartStat } from 'components/ui';
import { isAuthenticated } from 'auth';
import { useQuery } from 'api';

function ReporteEvolucion() {  
  const { data: stats } = useQuery('/reportes/estadisticas', {
    query: {
      fechaInicio: moment.utc().startOf('day').subtract(2, 'months').toISOString(),
      fechaFin: moment.utc().endOf('day').toISOString(),
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Title>Evolución</Title>
      </Grid>

      <Grid item xs={12}>
        {stats && (
          <Grid container spacing={3}>
            
            <GraficoEvolucion title="Casos activos por día" data={stats.activosPorDia} color={colors[0]} />

            <GraficoEvolucion title="Casos nuevos por día" data={stats.nuevosPorDia} color={colors[2]} />
            
            <GraficoEvolucion title="Fallecidos por día" data={stats.fallecidosPorDia} color={colors[4]} />
            
            <GraficoEvolucion title="Casos activos por día (Sector Urbano)" data={stats.activosUrbanoPorDia} color={colors[3]} />
            
            <GraficoEvolucion title="Casos activos por día (Sector Rural)" data={stats.activosRuralPorDia} color={colors[1]} />

            <GraficoEvolucion title="Recuperados por día" data={stats.recuperadosPorDia} color={colors[0]} />

            <GraficoEvolucion title="Hospitalizados por día" data={stats.hospitalizadosPorDia} color={colors[2]} />

            <GraficoEvolucion title="Contactos estrechos ingresados por día" data={stats.contactosPorDia} color={colors[1]} />

            <GraficoEvolucion title="Pacientes en residencia sanitaria por día" data={stats.residenciaPorDia} color={colors[3]} />

            <GraficoEvolucion title="Tests PCR tomados por día" data={stats.testsPcrPorDia} color={colors[4]} />
            
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const colors = ['#225560', '#8EB8E5', '#FFD166', '#D8829D', '#D33F49'];

function GraficoEvolucion({ title, data, color }) {
  return (
    <Grid item xs={12}>
      <ChartStat
        title={title}
        type="bar"
        height={300}
        data={{
          datasets: [{
            data: data.map(x => Number(x.cantidad)),
            backgroundColor: color,
            minBarLength: 2,
          }],
          labels: data.map(x => moment(x.dia).format('D MMM ')),
        }}
        options={{
          maintainAspectRatio: false,
          aspectRatio: 1.2,
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              type: 'linear',
              ticks: {
                max: 1 + max(data.map(x => Number(x.cantidad)))
              }
            }]
          }
        }}
      />
    </Grid>
  )
}

export default isAuthenticated(ReporteEvolucion);
