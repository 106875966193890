import React from 'react';
import Admin from 'components/admin';
import CentroAsistencialForm from 'components/listados/CentroAsistencialForm';
import { formatDireccion } from 'helpers';
import { PERMISO } from '../../constants';

export default function CentroSaludPages() {
  return (
    <Admin
      createTitle="Nuevo Centro Asistencial"
      listTitle="Centros Asistenciales"
      editTitle="Editar Centro Asistencial"
      routePath="/admin/centros-asistenciales"
      apiPath="/centros-asistenciales"
      FormComponent={CentroAsistencialForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre', 'Teléfono', 'Dirección']}
      rowItems={row => [row.id, row.nombre, row.telefono, formatDireccion(row.direccion)]}
    />
  )
}
