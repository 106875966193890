import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router } from '@reach/router';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { AuthProvider } from './auth';
import theme from './theme'
import './App.css';

import Dashboard from './components/dashboard/Dashboard';
import NotFoundPage from './pages/NotFoundPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import AdminUsuarios from './pages/usuario';
import AdminDepartamento from './pages/departamento';
import AdminEnfermedades from './pages/enfermedad';
import AdminPacientes from './pages/paciente';
import AdminRoles from './pages/rol';
import AdminResidenciaSanitaria from './pages/residenciaSanitaria';
import AdminCentrosSalud from './pages/centroSalud';
import AdminCriteriosMuestra from './pages/criteriosMuestra';
import AdminEstrategiasMuestra from './pages/estrategiaMuestra';
import AdminLaboratorios from './pages/laboratorio';
import AdminCentroAsistencial from './pages/centroAsistencial';
import AdminEstadoPaciente from './pages/estadoPaciente';
import AdminSintoma from './pages/sintoma';
import AdminSistemaSalud from './pages/sistemaSalud';
import AdminTipoMuestra from './pages/tipoMuestra';
import AdminTipoAyuda from './pages/tipoAyuda';
import AdminEstadoAyuda from './pages/estadoAyuda';
import AdminMuestraBac from './pages/muestraBac';
import EditarPerfil from './pages/EditarPerfil';
import CambiarClave from './pages/CambiarClave';
import Estadisticas from './pages/estadisticas';

moment.defineLocale('es', {
  ...require('moment/locale/es'),
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
});
moment.locale('es');

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <AuthProvider>
            <ErrorPage>
              <Router>
                <LoginPage path="/login" />
                <CambiarClave path="/cambiar-clave" />
                
                <Dashboard path="/">
                  <Estadisticas path="/" />
                  <EditarPerfil path="/editar-perfil" />

                  <AdminPacientes path="/admin/pacientes/*" />
                  <AdminUsuarios path="/admin/funcionarios/*" />
                  <AdminDepartamento path="/admin/departamentos/*" />
                  <AdminRoles path="/admin/roles/*" />
                  <AdminEnfermedades path="/admin/enfermedades/*" />
                  <AdminResidenciaSanitaria path="/admin/residencias-sanitarias/*" />
                  <AdminCentrosSalud path="/admin/centros-salud/*" />
                  <AdminCriteriosMuestra path="/admin/criterios-toma-muestras/*" />
                  <AdminEstrategiasMuestra path="/admin/estrategias-toma-muestras/*" />
                  <AdminLaboratorios path="/admin/tomas-muestras/*" />
                  <AdminCentroAsistencial path="/admin/centros-asistenciales/*" />
                  <AdminEstadoPaciente path="/admin/estados-paciente/*" />
                  <AdminSintoma path="/admin/sintomas/*" />
                  <AdminSistemaSalud path="/admin/sistemas-salud/*" />
                  <AdminTipoMuestra path="/admin/tipos-muestra/*" />
                  <AdminTipoAyuda path="/admin/tipos-ayuda/*" />
                  <AdminEstadoAyuda path="/admin/estados-ayuda/*" />
                  <AdminMuestraBac path="/admin/muestras-bac/*" />

                </Dashboard>
                
                <NotFoundPage default />
              </Router>
            </ErrorPage>
          </AuthProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
