import React from 'react';
import { Select as MuiSelect, InputLabel, FormHelperText } from '@material-ui/core';
import { StyledFormControl } from './styledComponents';

export function Select ({ error, label, fluid, ...props }) {
  return (
    <StyledFormControl error={!!error} fluid={fluid}>
      {label && <InputLabel>{label}</InputLabel>}
      <MuiSelect {...props} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
}
