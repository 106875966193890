import React, { useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import RolForm from 'components/rol/RolForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';

function EditarRol({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: rol, loading } = useQuery(`/roles/${id}`);
  const [editarRol, { loading: posting, error }] = useMutation(`/roles/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarRol({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'roles', title: 'Roles', to: '/admin/roles' },
        { key: 'actual', title: rol ? rol.nombre : id, to: `/admin/roles/${id}`, active: true },
      ]} />
      <Title>Editar Rol</Title>
      <Loader loading={loading || posting} />

      {rol && 
        <RolForm
          rol={rol}
          error={error}
          onSubmit={handleSubmit}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.RolesModificar])(EditarRol);
