import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import { Button, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Add, Delete, Save as SaveIcon } from '@material-ui/icons';
import { FieldArray, useFormikContext } from 'formik';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import DireccionForm, { validateDireccion } from 'components/direccion/DireccionForm';
import { validateRut, validateLength } from 'helpers';
import { useLazyQuery, useQuery } from 'api';

const getInitialValues = ({ paciente }) => ({
  rut: paciente ? paciente.rut : '',
  primerNombre: paciente ? paciente.primerNombre : '',
  segundoNombre: paciente ? paciente.segundoNombre : '',
  primerApellido: paciente ? paciente.primerApellido : '',
  segundoApellido: paciente ? paciente.segundoApellido : '',
  fechaNacimiento: paciente ? paciente.fechaNacimiento : '',
  sexo: paciente ? paciente.sexo : '',
  direccion: {
    idTipo: paciente ? paciente.direccion.idTipo.toString() : '1',
    calle: paciente ? paciente.direccion.calle : '',
    numero: paciente ? paciente.direccion.numero : '',
    villa: paciente ? paciente.direccion.villa : '',
    otro: paciente ? paciente.direccion.otro : '',
    comuna: paciente ? paciente.direccion.sector.comuna : null,
    sector: paciente ? paciente.direccion.sector : null,
    idSector: paciente ? paciente.direccion.idSector : '',
  },
  telefono1: paciente ? paciente.telefono1 : '',
  telefono2: paciente ? paciente.telefono2 : '',
  idSistemaSalud: paciente ? paciente.idSistemaSalud : '',
  ayudas: paciente?.ayudas || [],
});

function PacienteForm ({ paciente, error, onSubmit }) {
  const initialValues = getInitialValues({ paciente });
  
  const { data: sistemasSalud } = useQuery('/sistemas-salud', {}, []);
  const { data: estadosAyuda } = useQuery('/estados-ayuda', {}, []);
  const [buscarTiposAyuda] = useLazyQuery('/tipos-ayuda');

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { direccion: { sector, comuna, ...direccionInput }, ...pacienteValues } = values;
    pacienteValues.direccion = direccionInput;
    pacienteValues.ayudas = pacienteValues.ayudas.map(pa => ({
      idTipoAyuda: pa.tipoAyuda?.id,
      cantidad: Number(pa.cantidad),
      idEstadoAyuda: pa.idEstadoAyuda,
    }))

    onSubmit(pacienteValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <FormUpdater paciente={paciente} />
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="rut" label="RUT" fluid />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerNombre" label="Primer Nombre" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoNombre" label="Segundo Nombre" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerApellido" label="Primer Apellido" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoApellido" label="Segundo Apellido" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.Select required name="sexo" label="Sexo" fluid align="left">
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Femenino</MenuItem>
            </Form.Select>
          </Form.Field>

          <Form.Field xs={12} md={3}>
            <Form.Datepicker required name="fechaNacimiento" label="Fecha nacimiento" fluid InputLabelProps={{ shrink: true }} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="telefono1" label="Teléfono 1" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="telefono2" label="Teléfono de Contacto" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select name="idSistemaSalud" label="Sistema de Salud" fluid align="left">
              {sistemasSalud.map(ss => <MenuItem key={ss.id} value={ss.id}>{ss.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Dirección</Typography>
          </Form.Field>

          <DireccionForm values={values.direccion} path="direccion" />

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Ayudas Sociales</Typography>
          </Form.Field>
          <FieldArray name="ayudas" render={arrayHelpers => (
            <>
              {values.ayudas.map((ayuda, i) => (
                <>
                  <Form.Field xs={5} md={6}>
                    <Form.Lookup
                      required
                      name={`ayudas.${i}.tipoAyuda`}
                      label="Tipo de Ayuda"
                      fluid
                      filterOptions={options => options.filter(o => !values.ayudas.some(a => a.tipoAyuda?.id === o.id))}
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option ? option.nombre : ''}
                      fetcher={(busqueda) => buscarTiposAyuda({ query: { busqueda }}).then(resp => resp.data)}
                    />
                  </Form.Field>
                  <Form.Field xs={3} md={2}>
                    <Form.TextField
                      required
                      type="number"
                      name={`ayudas.${i}.cantidad`}
                      label="Cantidad"
                      fluid
                    />
                  </Form.Field>
                  <Form.Field xs={3}>
                    <Form.Select
                      required
                      name={`ayudas.${i}.idEstadoAyuda`}
                      label="Estado"
                      fluid
                      align="left"
                    >
                      {estadosAyuda.map(ea => <MenuItem key={ea.id} value={ea.id}>{ea.nombre}</MenuItem>)}
                    </Form.Select>
                  </Form.Field>
                  <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(i)}>
                    <Delete />
                  </IconButton>
                </>
              ))}
              {values.ayudas.length === 0 && (
                <Form.Field xs={12} align="left">
                  <Typography variant="subtitle2">El paciente no requiere ayudas sociales</Typography>
                </Form.Field>
              )}
              <Form.Field xs={12} align="left">
                <Form.ErrorMessage name="ayudas" />
                <Button variant="contained" size="small" startIcon={<Add/>} onClick={() => arrayHelpers.push({ tipoAyuda: null, cantidad: '1', idEstadoAyuda: '' })}>
                  Agregar Ayuda
                </Button>
              </Form.Field>
            </>
          )}/>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function FormUpdater({ paciente }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ paciente }))
  }, [paciente]);

  return null;
}

function validateForm(values) {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'El RUT es obligatorio'
  } else if(!validateRut(values.rut)) {
    errors.rut = 'El RUT ingresado no es correcto';
  }
  if (!values.primerNombre) {
    errors.primerNombre = 'El nombre es obligatorio';
  }
  if (!values.primerApellido) {
    errors.primerApellido = 'El apellido es obligatorio';
  }
  if (!values.fechaNacimiento) {
    errors.fechaNacimiento = 'La fecha de nacimiento es obligatoria';
  }
  if (!values.sexo) {
    errors.sexo = 'El sexo es obligatorio';
  }
  if (!values.telefono1) {
    errors.telefono1 = 'El teléfono es obligatorio';
  }

  const direccionErrors = validateDireccion(values.direccion);
  if (direccionErrors) {
    errors.direccion = direccionErrors;
  }

  const ayudaErrors = values.ayudas.map((pa) => {
    const ayudaErr = {};
    if (!pa.tipoAyuda) {
      ayudaErr.tipoAyuda = 'El tipo de ayuda es obligatorio'
    }
    if (!pa.cantidad || Number(pa.cantidad) <= 0) {
      ayudaErr.cantidad = 'La cantidad es obligatoria'
    }
    if (!pa.idEstadoAyuda) {
      ayudaErr.idEstadoAyuda = 'El estado es obligatorio'
    }
    return ayudaErr;
  })

  if (ayudaErrors.some(err => !isEmpty(err))) {
    errors.ayudas = ayudaErrors;
  }

  return errors;
}

PacienteForm.propTypes = {
  paciente: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default PacienteForm;