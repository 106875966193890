import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, ActionsButton, Breadcrumbs, Title } from 'components/ui';
import { useQuery } from 'api';
import { navigate } from '@reach/router';

export default function ListAdmin({ title, routePath, apiPath, modifyPerms, headerItems, rowItems }) {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  
  const canEdit = auth.hasPermission(modifyPerms);

  const { data: items, loading, refetch } = useQuery(apiPath, { query: state });
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title, to: routePath, active: true },
      ]} />
      <Title>{title}</Title>
      <Loader loading={loading} />
      
      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={renderHeader(headerItems)}
        renderRow={renderRow({ rowItems, canEdit, routePath })}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate(`${routePath}/nuevo`)}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

const renderHeader = headerItems => () => {
  return (
    <TableRow>
      {headerItems.map((item, idx) => <TableCell key={idx}>{item}</TableCell>)}
      <TableCell></TableCell>
    </TableRow>
  )
}

const renderRow = ({ rowItems, canEdit, routePath }) => row => {
  return (
    <TableRow>
      {rowItems(row).map((item, idx) => <CompactTableCell key={idx}>{item}</CompactTableCell>)}
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`${routePath}/${row.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}
