import React, { useState } from 'react';
import { Button, TableRow, TableCell, Box } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import MuestraModal from 'components/ficha/MuestraModal';
import { DataTable, Loader, CompactTableCell, ActionsButton } from 'components/ui';
import { useMutation, useQuery } from 'api';
import { formatDate } from 'helpers/format';
import { ESTADOS, PERMISO } from '../../constants';

function ListadoMuestra({ ficha }) {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  
  const canEdit = auth.hasPermission([PERMISO.FichasModificar]);
  const fallecido = ficha.seguimientos.some(seg => seg.estadoPaciente?.nombre === 'Fallecido');

  const { data: muestras, loading, refetch: refetchMuestras } = useQuery(`/fichas/${ficha.id}/muestras`);
  const [crearMuestra] = useMutation(`/fichas/${ficha.id}/muestras`, { method: 'post' })
  const [editarMuestra] = useMutation(`/fichas/${ficha.id}/muestras/${modalData?.id}`, { method: 'patch' })

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetchMuestras({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });

  const openModal = (muestra) => {
    setModalData(muestra);
    setModalOpen(true);
  }
  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  }

  const handleMuestraSubmit = async values => {
    let resp;
    if (modalData) {
      resp = await editarMuestra({ body: values });
    } else {
      resp = await crearMuestra({ body: values });
    }
  
    if (!resp.error) {
      refetchMuestras();
      closeModal();
    }
  }
  
  return (
    <div>
      <Loader loading={loading} />

      <DataTable
        data={muestras}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <MuestraHeader />}
        renderRow={(muestra) => (
          <MuestraRow
            key={muestra.id}
            muestra={muestra}
            canEdit={canEdit}
            onEdit={() => openModal(muestra)}
          />
        )}
      />

      {canEdit && !fallecido && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            align="right"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => openModal(null)}
          >
            Agregar
          </Button>
        </Box>
      )}

      <MuestraModal
        ficha={ficha}
        muestra={modalData}
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleMuestraSubmit}
      />

    </div>
  );
}

function MuestraHeader() {
  return (
    <TableRow>
      <TableCell>#</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Fecha Muestra</TableCell>
      <TableCell>Resultado</TableCell>
      <TableCell>Fecha Resultado</TableCell>
      <TableCell>Fecha Notificación</TableCell>
      <TableCell>Toma de Muestra</TableCell>
      <TableCell>Criterio</TableCell>
      <TableCell>Estrategia</TableCell>
      <TableCell>#Seguimiento<br/>Solicitado</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function MuestraRow({ muestra, canEdit, onEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{muestra.id}</CompactTableCell>
      <CompactTableCell>{muestra.tipoMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>{formatDate(muestra.fechaMuestra)}</CompactTableCell>
      <CompactTableCell>{ESTADOS[muestra.estado]}</CompactTableCell>
      <CompactTableCell>{formatDate(muestra.fechaResultado)}</CompactTableCell>
      <CompactTableCell>{formatDate(muestra.fechaNotificacion)}</CompactTableCell>
      <CompactTableCell>{muestra.laboratorio?.nombre}</CompactTableCell>
      <CompactTableCell>{muestra.criterioMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>{muestra.estrategiaMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>{muestra.idSeguimiento}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: onEdit }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.FichaVer])(ListadoMuestra);
