import React, { useRef, useEffect } from 'react';
import { makeStyles, Container, Paper } from '@material-ui/core';
import { isAuthenticated } from 'auth';
import { useQuery } from 'api';

import Navbar from '../navbar/Navbar';
import PrintButton from '../navbar/PrintButton';
import Sidebar from '../sidebar/Sidebar';
import Footer from './Footer';

function Dashboard ({ location, navigate, children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const classes = useStyles();
  
  const { data: usuario } = useQuery('/usuarios/me');
  const componentRef = useRef();

  useEffect(() => {
    if (usuario && !usuario.fechaRenovacionClave && location.pathname !== '/cambiar-clave') {
      navigate('/cambiar-clave');
    }
  }, [usuario, location, navigate])

  return (
    <div className={classes.root}>
      <Navbar usuario={usuario} onMenuClick={handleDrawerToggle}>
        <PrintButton ref={componentRef} />
      </Navbar>
      <Sidebar usuario={usuario} open={mobileOpen} toggleDrawer={handleDrawerToggle} />
      <DashboardContent classes={classes} children={children} ref={componentRef} />
    </div>
  );
}

class DashboardContent extends React.Component {
  render() {
    const { classes, children } = this.props;
    
    return (
      <div className={classes.content}>
        
        <main className={classes.main} ref={el => (this.componentRef = el)}>
          <Container maxWidth="xl">
            <Paper elevation={0} className={classes.paper}>
              {children}
            </Paper>
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
  },
  main: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(5),
    minHeight: '80vh',
  },
}));

export default isAuthenticated(Dashboard);