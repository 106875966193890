import React from 'react';
import Admin from 'components/admin';
import SintomaForm from 'components/listados/SintomaForm';
import { PERMISO } from '../../constants';

export default function CentroSaludPages() {
  return (
    <Admin
      createTitle="Nuevo Síntoma"
      listTitle="Síntomas"
      editTitle="Editar Síntoma"
      routePath="/admin/sintomas"
      apiPath="/sintomas"
      FormComponent={SintomaForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
