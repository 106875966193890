/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useField } from 'formik';
import { Select as SelectField } from '../Select';

export function Select({ onChange, ...props }) {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error;
  
  useEffect(() => {
    if (onChange) {
      onChange(field.value);
    }
  }, [field.value]);

  return (
    <SelectField
      {...field}
      {...props}
      value={field.value || ''}
      error={error}
    />
  );
}
