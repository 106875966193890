import { DateTimePicker } from '@material-ui/pickers';
import { StyledFormControl } from 'components/ui/styledComponents';

export function DateTime(props) {
  const { fluid, ...other } = props;
  
  return (
    <StyledFormControl fluid={fluid}>
      <DateTimePicker
        {...other}
      />
    </StyledFormControl>
  )
}