import React from 'react';
import Admin from 'components/admin';
import CentroSaludForm from 'components/listados/CentroSaludForm';
import { PERMISO } from '../../constants';

export default function CentroSaludPages() {
  return (
    <Admin
      createTitle="Nuevo Centro de Salud"
      listTitle="Centros de Salud"
      editTitle="Editar Centro de Salud"
      routePath="/admin/centros-salud"
      apiPath="/centros-salud"
      FormComponent={CentroSaludForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
