import React from 'react';
import { Grid } from '@material-ui/core';
import { Title, NumberStat, ChartStat } from 'components/ui';
import { isAuthenticated } from 'auth';
import { useQuery } from 'api';

function ReporteDiario() {
  const { data: diario } = useQuery('/reportes/diario');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Title>Reporte Diario COVID-19, Comuna de Lautaro</Title>
      </Grid>

      <Grid item xs={12}>
        {diario && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <NumberStat title="Casos Activos" value={diario.casosActivos} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Casos Nuevos" value={diario.casosNuevos} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Casos Probables" value={diario.casosProbables} valueProps={{ color: 'secondary' }} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Fallecidos" value={diario.fallecidos} valueProps={{ color: 'error' }}/>
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Recuperados" value={diario.recuperados} valueProps={{ color: 'primary' }} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Hospitalizados" value={diario.hospitalizados} valueProps={{ color: 'secondary' }} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Contactos en Seguimiento" value={diario.contactosSeguimiento} valueProps={{ style: { color: '#8EB8E5' } }} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="En Residencia Sanitaria" value={diario.casosResidencia} />
            </Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Histórico de Pacientes" value={diario.casosHistorico} />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberStat title="Positividad" value={`${diario.porcentajePositividad} %`} valueProps={{ color: 'secondary' }} />
            </Grid>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={12} md={6}>
              <ChartStat
                title="Casos Activos por Sector"
                type="doughnut"
                data={{
                  datasets: [{
                    data: diario.casosPorTipoDireccion.map(x => Number(x.cantidad)),
                    backgroundColor: colors,
                  }],
                  labels: diario.casosPorTipoDireccion.map(x => x.tipoDireccion),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartStat
                title="Distribución en la Comuna"
                type="doughnut"
                data={{
                  datasets: [{
                    data: diario.casosPorSector.map(x => Number(x.cantidad)),
                    backgroundColor: colors,
                  }],
                  labels: diario.casosPorSector.map(x => x.nombreSector),
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const colors = ['#225560', '#8EB8E5', '#FFD166', '#D8829D', '#D33F49'];

export default isAuthenticated(ReporteDiario);
