import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import PacienteForm from 'components/paciente/PacienteForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearPaciente() {
  const [crearPaciente, { loading, error }] = useMutation('/pacientes', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearPaciente({ body: values });

    if (!error) {
      navigate('/admin/pacientes');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'pacientes', title: 'Pacientes', to: '/admin/pacientes' },
        { key: 'actual', title: 'Nuevo Paciente', to: '/admin/pacientes/nuevo', active: true },
      ]} />
      <Title>Nuevo Paciente</Title>
      <Loader loading={loading} />

      <PacienteForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.PacientesModificar])(CrearPaciente);
