import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MuiAvatar, makeStyles } from '@material-ui/core';

export function Avatar({ usuario, size, src, ...props }) {
  const classes = useStyles();
  const iniciales = usuario ? usuario.nombres.toUpperCase()[0] + usuario.apellidos.toUpperCase()[0] : '';
  const nombreCompleto = `${usuario.nombres} ${usuario.apellidos}`;
  const urlFoto = usuario ? usuario.urlFoto : '';

  return (
    <MuiAvatar
      {...props}
      className={`${classes.avatar} ${classes[size]}`}
      src={src || urlFoto}
      alt={nombreCompleto}
    >
      {iniciales}
    </MuiAvatar>
  )
}

Avatar.propTypes = {
  usuario: PropTypes.shape({
    nombres: PropTypes.string.isRequired,
    apellidos: PropTypes.string.isRequired,
    urlFoto: PropTypes.string,
  }),
  size: PropTypes.string,
  src: PropTypes.node,
}

Avatar.defaultProps = {
  size: 'normal',
}

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  normal: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));
