import React from 'react';
import { Container, Typography } from '@material-ui/core';

export default function NotFoundPage() {
  return (
    <Container style={{textAlign: 'center', marginTop: 100}}>
      <Typography variant="h2">No encontrado</Typography>

      <Typography>La página que está intentando acceder no se encuentra disponible.</Typography>
    </Container>
  )
}