import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Save as SaveIcon } from '@material-ui/icons';
import { useLazyQuery } from 'api';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateLength } from 'helpers';

function LaboratorioForm ({ item, error, onSubmit }) {
  const [buscarComunas] = useLazyQuery('/comunas');

  const initialValues = {
    nombre: item ? item.nombre : '',
    comuna: item ? item.comuna : null,
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { comuna, ...laboratorioInput } = values;
    laboratorioInput.idComuna = comuna.id;

    onSubmit(laboratorioInput);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form alignItems="left">
          <Form.Field xs={12} md={6}>
            <Form.TextField required name="nombre" label="Nombre" validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={6}>
            <Form.Lookup
              required
              name={'comuna'}
              label="Comuna"
              fluid
              groupBy={(option) => option.region.nombre}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? option.nombre : ''}
              fetcher={(busqueda) => buscarComunas({ query: { busqueda }}).then(resp => resp.data)}
            />
          </Form.Field>
          
          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = 'El nombre es obligatorio'
  }
  if (!values.comuna) {
    errors.comuna = 'La comuna es obligatoria'
  }
  
  return errors;
}

LaboratorioForm.propTypes = {
  item: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default LaboratorioForm;