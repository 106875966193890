import React from 'react';
import Admin from 'components/admin';
import LaboratorioForm from 'components/listados/LaboratorioForm';
import { formatComuna } from 'helpers';
import { PERMISO } from '../../constants';

export default function LaboratorioPages() {
  return (
    <Admin
      createTitle="Nueva Toma de Muestras"
      listTitle="Tomas de Muestras"
      editTitle="Editar Toma de Muestras"
      routePath="/admin/tomas-muestras"
      apiPath="/laboratorios"
      FormComponent={LaboratorioForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre', 'Comuna']}
      rowItems={row => [row.id, row.nombre, formatComuna(row.comuna)]}
    />
  )
}
