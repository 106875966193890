import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { Button, MenuItem } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, ApiErrorMessage, TextField } from 'components/ui';
import { useLazyQuery, useQuery } from 'api';
import { formatPacienteDetallado, formatResidenciaSanitaria, validateLength } from 'helpers';
import { useFormikContext } from 'formik';
import DireccionForm, { validateDireccion } from 'components/direccion/DireccionForm';

const getInitialValues = ({ ficha, paciente }) => ({
  paciente: ficha ? ficha.paciente : paciente,
  idEnfermedad: ficha ? ficha.idEnfermedad : 1,
  fechaInicioCuarentena: ficha ? ficha.fechaInicioCuarentena : '',
  fechaFinCuarentena: ficha ? ficha.fechaFinCuarentena : '',
  direccionCuarentena: getInitialValuesDireccion(ficha?.direccionCuarentena),
  residenciaCuarentena: ficha ? ficha.residenciaCuarentena : null,
  tipoCuarentena: ficha?.residenciaCuarentena ? '2' : (ficha?.idDireccionCuarentena === paciente.idDireccion ? '1' : '3'),
  idCentroSalud: ficha?.idCentroSalud || '',
  observaciones: ficha?.observaciones || '',
  casoProbable: ficha?.casoProbable || false,
  embarazada: ficha?.semanasEmbarazo > 0 || false,
  semanasEmbarazo: ficha?.semanasEmbarazo || 0,
  sintomas: ficha ? ficha.sintomas : [],
});

const getInitialValuesDireccion = (direccion) => ({
  idTipo: direccion?.idTipo.toString() || '1',
  calle: direccion?.calle || '',
  numero: direccion?.numero || '',
  villa: direccion?.villa || '',
  otro: direccion?.otro || '',
  comuna: direccion?.sector.comuna || null,
  sector: direccion?.sector || null,
  idSector: direccion?.sector.id || '',
})

function FichaForm ({ paciente, ficha, error, onSubmit }) {
  const { data: enfermedades } = useQuery('/enfermedades', {} , []);
  const { data: centrosSalud } = useQuery('/centros-salud', {}, []);
  const { data: sintomas } = useQuery('/sintomas', {}, []);
  const [buscarResidencias] = useLazyQuery('/residencias-sanitarias');

  const initialValues = getInitialValues({ ficha, paciente });

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { paciente, residenciaCuarentena, tipoCuarentena, sintomas, embarazada, ...fichaValues } = values;
    fichaValues.idPaciente = paciente.id;
    if (residenciaCuarentena) {
      fichaValues.idResidenciaCuarentena = residenciaCuarentena.id;
    }
    fichaValues.semanasEmbarazo = embarazada ? fichaValues.semanasEmbarazo : 0;
    if (sintomas) {
      fichaValues.sintomas = sintomas.map(s => s.id);
    }

    onSubmit(fichaValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, errors, setFieldValue, setFieldTouched, handleSubmit }) => (
        <Form alignItems="center">
          <FormUpdater ficha={ficha} paciente={paciente} />
          <Form.Field xs={12} md={6}>
            <TextField
              label="Paciente"
              value={values.paciente ? formatPacienteDetallado(values.paciente) : ''}
              fluid
              variant="outlined"
              disabled
            />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select required name="idEnfermedad" label="Enfermedad" fluid align="left">
              {enfermedades.map((enf) => (
                <MenuItem key={enf.id} value={enf.id}>{enf.nombre}</MenuItem>
              ))}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select name="idCentroSalud" label="Centro de Salud" fluid align="left">
              {centrosSalud.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={7}>
            <Form.Autocomplete
              name="sintomas"
              label="Síntomas / Signos"
              multiple
              options={sintomas}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? option.nombre : ''}
              filterSelectedOptions
              fluid
            />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Checkbox name="casoProbable" label="Caso Probable" />
          </Form.Field>
          <Form.Field xs={12} md={2}></Form.Field>

          {paciente.sexo === 'F' ? (
            <>
              <Form.Field xs={12} md={2}>
                <Form.Checkbox name="embarazada" label="Embarazada" />
              </Form.Field>
              {values.embarazada ? (
                <Form.Field xs={12} md={3}>
                  <Form.TextField type="number" name="semanasEmbarazo" label="Semanas Gestación" fluid />
                </Form.Field>  
              ) : null}
              <Form.Field xs={12} md={values.embarazada ? 7 : 10}></Form.Field>
            </>
          ) : (
            null
          )}

          <Form.Field xs={12} md={3}>
            <Form.Datepicker
              name="fechaInicioCuarentena"
              label="Inicio cuarentena"
              fluid
              InputLabelProps={{ shrink: true }}
              onChange={fecha => {
                if (fecha) {
                  const enfermedad = enfermedades.find(e => e.id === values.idEnfermedad);
                  setFieldValue('fechaFinCuarentena', moment(fecha).add(enfermedad.diasCuarentena, 'days').toISOString());
                  setFieldTouched('fechaFinCuarentena');
                }
              }}
            />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Datepicker name="fechaFinCuarentena" label="Fin cuarentena" fluid InputLabelProps={{ shrink: true }} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select
              name="tipoCuarentena"
              label="Lugar Cuarentena"
              fluid
              align="left"
              onChange={tipo => {
                if (tipo === '1') {
                  setFieldValue('direccionCuarentena', getInitialValuesDireccion(paciente.direccion));
                  setFieldTouched('direccionCuarentena');
                }
              }}
            >
              <MenuItem value="1">Domicilio del paciente</MenuItem>
              <MenuItem value="2">Residencia sanitaria</MenuItem>
              <MenuItem value="3">Nueva dirección</MenuItem>
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}></Form.Field>
          {values.tipoCuarentena === '2' ? (
            <Form.Field xs={12} md={6}>
              <Form.Lookup
                required
                name="residenciaCuarentena"
                label="Residencia Sanitaria"
                fluid
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option ? formatResidenciaSanitaria(option) : ''}
                fetcher={(busqueda) => buscarResidencias({ query: { busqueda }}).then(resp => resp.data)}
              />
            </Form.Field>
          ) : (
            <DireccionForm values={values.direccionCuarentena} path="direccionCuarentena" />
          )}

          <Form.Field xs={12}>
            <Form.TextField name="observaciones" label="Observaciones" fluid multiline validate={validateLength(0, 500)} />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Form.Field xs={12} align="right">
            <Button
              variant="contained"
              align="right"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
            >
              Guardar
            </Button>            
          </Form.Field>
        </Form>
      )}
    </Formik>
  )
}

function FormUpdater({ ficha, paciente }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ ficha, paciente }))
  }, [ficha]);

  return null;
}

function validateForm(values) {
  const errors = {};

  if (!values.paciente) {
    errors.paciente = 'El paciente es obligatorio'
  }
  if (!values.idEnfermedad) {
    errors.idEnfermedad = 'La enfermedad es obligatoria';  
  }
  if (values.fechaInicioCuarentena) {
    if (!values.fechaFinCuarentena) {
      errors.fechaFinCuarentena = 'El fin de cuarentena es obligatorio';
    }
    if (values.tipoCuarentena === '2' && !values.residenciaCuarentena) {
      errors.residenciaCuarentena = 'La residencia es obligatoria';
    } else {
      const direccionErrors = validateDireccion(values.direccionCuarentena);
      if (direccionErrors) {
        errors.direccionCuarentena = direccionErrors;
      }
    }
    if (values.embarazada && !values.semanasEmbarazo) {
      errors.semanasEmbarazo = 'El dato es obligatorio';
    }
  }

  return errors;
}

FichaForm.propTypes = {
  paciente: PropTypes.object,
  ficha: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default FichaForm;