import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, TableContainer, TablePagination, TableHead, TableBody } from '@material-ui/core';

export function DataTable({ data, loading, renderHeader, renderRow, page, pageSize, onPageChange, onPageSizeChange, ...props }) {
  const hasPagination = !!page && !!pageSize && !!onPageChange && !!onPageSizeChange;
  
  return (
    <TableContainer>
      <Table {...props}>
        <TableHead>
          {renderHeader()}
        </TableHead>
        <TableBody>
          {data ? data.nodes.map((row, i) => <Fragment key={i}>{renderRow(row, i)}</Fragment>) : null}
        </TableBody>
      </Table>
      {hasPagination && (
        <TablePagination
          component="div"
          count={data ? data.count : 0}
          page={page - 1}
          rowsPerPage={pageSize}
          onChangePage={(e, page) => onPageChange(page + 1)}
          onChangeRowsPerPage={(e, option) => onPageSizeChange(Number(option.key))}
        />
      )}
    </TableContainer>
  )
}

DataTable.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
  }),
  loading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  renderHeader: PropTypes.func.isRequired,
  renderRow: PropTypes.func.isRequired,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
}

DataTable.defaultProps = {
  data: { nodes: [], count: 0, page: 1, pageSize: 10 },
}
