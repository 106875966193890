import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { Button, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Add, Delete, Save as SaveIcon } from '@material-ui/icons';
import { FieldArray, useFormikContext } from 'formik';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import DireccionForm, { validateDireccion } from 'components/direccion/DireccionForm';
import { validateRut, validateLength } from 'helpers';
import { useLazyQuery, useQuery } from 'api';

const getInitialValues = ({ muestra }) => ({
  rut: muestra ? muestra.rut : '',
  primerNombre: muestra ? muestra.primerNombre : '',
  segundoNombre: muestra ? muestra.segundoNombre : '',
  primerApellido: muestra ? muestra.primerApellido : '',
  segundoApellido: muestra ? muestra.segundoApellido : '',
  fechaNacimiento: muestra ? muestra.fechaNacimiento : '',
  sexo: muestra ? muestra.sexo : '',
  direccion: {
    idTipo: muestra ? muestra.direccion.idTipo.toString() : '1',
    calle: muestra ? muestra.direccion.calle : '',
    numero: muestra ? muestra.direccion.numero : '',
    villa: muestra ? muestra.direccion.villa : '',
    otro: muestra ? muestra.direccion.otro : '',
    comuna: muestra ? muestra.direccion.sector.comuna : null,
    sector: muestra ? muestra.direccion.sector : null,
    idSector: muestra ? muestra.direccion.idSector : '',
  },
  telefono1: muestra ? muestra.telefono1 : '',
  idTipoMuestra: muestra ? muestra.idTipoMuestra : '',
  fechaMuestra: muestra ? muestra.fechaMuestra : moment().toISOString(),
  estado: muestra ? `${muestra.estado}` : '0',
  idCriterioMuestra: muestra?.idCriterioMuestra || '',
  idEstrategiaMuestra: muestra?.idEstrategiaMuestra || '',
});

function MuestraBacForm ({ muestra, error, onSubmit }) {
  const initialValues = getInitialValues({ muestra });
  
  const { data: tiposMuestra } = useQuery('/tipos-muestra', {}, []);
  const { data: criteriosMuestra } = useQuery('/criterios-muestra', {}, []);
  const { data: estrategiasMuestra } = useQuery('/estrategias-muestra', {}, []);

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { direccion: { sector, comuna, ...direccionInput }, ...muestraValues } = values;
    muestraValues.direccion = direccionInput;

    onSubmit(muestraValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <FormUpdater muestra={muestra} />
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="rut" label="RUT" fluid />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerNombre" label="Primer Nombre" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoNombre" label="Segundo Nombre" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerApellido" label="Primer Apellido" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoApellido" label="Segundo Apellido" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.Select required name="sexo" label="Sexo" fluid align="left">
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Femenino</MenuItem>
            </Form.Select>
          </Form.Field>

          <Form.Field xs={12} md={3}>
            <Form.Datepicker required name="fechaNacimiento" label="Fecha nacimiento" fluid InputLabelProps={{ shrink: true }} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="telefono1" label="Teléfono 1" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Dirección</Typography>
          </Form.Field>

          <DireccionForm values={values.direccion} path="direccion" />

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos de la Muestra</Typography>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select required name="idTipoMuestra" label="Tipo" fluid align="left">
              {tiposMuestra.map(tm => <MenuItem key={tm.id} value={tm.id}>{tm.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Datepicker required name="fechaMuestra" label="Fecha muestra" fluid InputLabelProps={{ shrink: true }} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Select name="estado" label="Resultado" fluid align="left">
              <MenuItem value="0">Pendiente</MenuItem>
              <MenuItem value="1">Negativo</MenuItem>
              <MenuItem value="2">Positivo</MenuItem>
              <MenuItem value="3">No Concluyente</MenuItem>
            </Form.Select>
          </Form.Field>

          <Form.Field xs={12} md={4}>
            <Form.Select name="idCriterioMuestra" label="Criterio" fluid align="left">
              {criteriosMuestra.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select name="idEstrategiaMuestra" label="Estrategia" fluid align="left">
              {estrategiasMuestra.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function FormUpdater({ muestra }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ muestra }))
  }, [muestra]);

  return null;
}

function validateForm(values) {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'El RUT es obligatorio'
  } else if(!validateRut(values.rut)) {
    errors.rut = 'El RUT ingresado no es correcto';
  }
  if (!values.primerNombre) {
    errors.primerNombre = 'El nombre es obligatorio';
  }
  if (!values.primerApellido) {
    errors.primerApellido = 'El apellido es obligatorio';
  }
  if (!values.fechaNacimiento) {
    errors.fechaNacimiento = 'La fecha de nacimiento es obligatoria';
  }
  if (!values.sexo) {
    errors.sexo = 'El sexo es obligatorio';
  }
  if (!values.telefono1) {
    errors.telefono1 = 'El teléfono es obligatorio';
  }

  const direccionErrors = validateDireccion(values.direccion);
  if (direccionErrors) {
    errors.direccion = direccionErrors;
  }

  if (!values.idTipoMuestra) {
    errors.idTipoMuestra = 'El tipo de muestra es obligatorio';
  }
  if (!values.fechaMuestra) {
    errors.fechaMuestra = 'La fecha de muestra es obligatoria';
  } else if (moment(values.fechaMuestra).isAfter()) {
    errors.fechaMuestra = 'La fecha de muestra no es válida';
  }
  if (!values.estado) {
    errors.estado = 'El resultado es obligatorio';
  }

  return errors;
}

MuestraBacForm.propTypes = {
  muestra: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default MuestraBacForm;