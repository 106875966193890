import moment from 'moment';
import { formatRut } from './usuario';

export function formatDate(date, format='DD/MM/YYYY') {
  const parsed = moment.utc(date);
  if(!date || !parsed.isValid()) {
    return null;
  }
  return parsed.format(format);
}

export function formatList(list) {
  return (list || []).map(s => s.nombre).join(', ')
}

export function truncate(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substr(0, maxLength) + '...';
  }
}

export function formatPaciente(ben) {
  return [ben.primerNombre, ben.segundoNombre, ben.primerApellido, ben.segundoApellido].filter(n => !!n).join(' ')
}

export function formatPacienteDetallado(ben) {
  return `${formatRut(ben.rut)} - ${ben.primerNombre} ${ben.primerApellido}`
}

export function formatDireccion(dir) {
  if (!dir) {
    return '';
  }
  if (dir.idTipo === 1) {
    return [dir.calle, dir.numero, dir.villa, dir.sector.nombre].filter(d => !!d).join(', ');
  } else if (dir.idTipo === 2) {
    return [dir.calle, dir.villa, dir.otro, dir.sector.nombre].filter(d => !!d).join(', ');
  }
  return '';
}

export function formatComuna(comuna) {
  return `${comuna.nombre}, ${comuna.region.nombre}`;
}

export function formatSector(sector) {
  return `${sector.nombre}, ${sector.comuna.nombre}`;
}

export function formatResidenciaSanitaria(res) {
  return [res.nombre, res.comuna?.nombre, res.comuna?.region?.nombre].filter(x => x).join(', ');
}

export function formatUsuario(usuario) {
  return `${usuario.nombres} ${usuario.apellidos}`;
}

export function roundDecimals(num, decimals) {
  const k = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * k) / k;
}
