import React, { useEffect, useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import { Tab, Tabs } from '@material-ui/core';
import { hasPermission, useAuth } from 'auth';
import { useMutation, useQuery } from 'api';
import FichaForm from 'components/ficha/FichaForm';
import ListadoSeguimiento from 'components/ficha/ListadoSeguimiento';
import ListadoMuestra from 'components/ficha/ListadoMuestra';
import { formatPaciente } from 'helpers';
import { PERMISO } from '../../constants';

function EditarFicha({ idPaciente, idFicha }) {
  const auth = useAuth();
  const [snackOpen, setSnackOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const { data: paciente, loading: loadingPaciente } = useQuery(`/pacientes/${idPaciente}`);
  const { data: ficha, loading: loadingFicha, refetch } = useQuery(`/fichas/${idFicha}`);
  const [editarFicha, { loading: posting, error }] = useMutation(`/fichas/${idFicha}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarFicha({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  useEffect(() => {
    refetch();
    setActiveTab(0);
  }, [idFicha]);

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'pacientes', title: 'Pacientes', to: '/admin/pacientes' },
        { key: 'paciente', title: paciente ? formatPaciente(paciente) : idPaciente, to: `/admin/pacientes/${idPaciente}` },
        { key: 'actual', title: `Ficha #${idFicha}`, to: `/admin/pacientes/${idPaciente}/fichas/${idFicha}`, actual: true }
      ]} />
      <Loader loading={loadingPaciente || loadingFicha || posting} />

      <Tabs
        value={activeTab}
        variant="fullWidth"
        onChange={(e, newTab) => setActiveTab(newTab)}
      >
        <Tab label="Editar Ficha" />
        <Tab label="Muestras" />
        {auth.hasPermission([PERMISO.SeguimientosVer]) &&
          <Tab label="Seguimientos" />}
      </Tabs>

      {paciente && ficha && activeTab === 0 &&
        <FichaForm
          paciente={paciente}
          ficha={ficha}
          error={error}
          onSubmit={handleSubmit}
        />
      }
      {paciente && ficha && activeTab === 1 &&
        <ListadoMuestra ficha={ficha} />
      }
      {paciente && ficha && activeTab === 2 &&
        <ListadoSeguimiento ficha={ficha} refetch={refetch}/>
      }


      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.FichasModificar])(EditarFicha);
