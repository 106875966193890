import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { AuthContext } from './AuthProvider';

const AuthWrapper = ({ component: Component, authFn, fallback, ...props }) => {
  const ctx = React.useContext(AuthContext);
  return authFn(ctx) ? <Component {...props} /> : fallback
}

AuthWrapper.propTypes = {
  component: PropTypes.elementType.isRequired,
  authFn: PropTypes.func.isRequired,
  fallback: PropTypes.node,
}

AuthWrapper.defaultProps = {
  fallback: null,
}

export const isAuthenticated = (component) => (props) =>
  <AuthWrapper
    {...props}
    component={component}
    authFn={(auth) => auth.isAuthenticated}
    fallback={<Redirect to="/login" noThrow />}
  />

export const isUnauthenticated = (component) => (props) =>
  <AuthWrapper
    {...props}
    component={component}
    authFn={(auth) => !auth.isAuthenticated || auth.user.rol === 'Administrador'}
    fallback={<Redirect to="/" noThrow />}
  />

export const hasPermissionFn = (user, perms) => perms.some(p => user.permisos.indexOf(p) >= 0) || user.rol === 'Administrador';

export const hasPermission = (perms) => (component) => (props) =>
  <AuthWrapper
    {...props}
    component={component}
    authFn={(auth) => hasPermissionFn(auth.user, perms)}
  />

export function useAuth() {
  const auth = React.useContext(AuthContext);
  return {
    auth,
    hasPermission: (perms) => hasPermissionFn(auth.user, perms),
  }
}