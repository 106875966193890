import React from 'react';
import Admin from 'components/admin';
import CriterioMuestraForm from 'components/listados/CriterioMuestraForm';
import { PERMISO } from '../../constants';

export default function CriterioMuestraPages() {
  return (
    <Admin
      createTitle="Nuevo Criterio de Tomas de Muestras"
      listTitle="Criterios de Tomas de Muestras"
      editTitle="Editar Criterio de Toma de Muestras"
      routePath="/admin/criterios-toma-muestras"
      apiPath="/criterios-muestra"
      FormComponent={CriterioMuestraForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
