import React from 'react';
import { Router } from '@reach/router';
import { hasPermission } from '../../auth';

import NotFoundPage from '../../pages/NotFoundPage';
import ListAdmin from './ListAdmin';
import CreateAdmin from './CreateAdmin';
import EditAdmin from './EditAdmin'

export default function Admin({
  createTitle, listTitle, editTitle, routePath, apiPath, FormComponent, viewPerms, modifyPerms, headerItems, rowItems, displayItem,
}) {
  const ListComponent = hasPermission(viewPerms)(ListAdmin);
  const CreateComponent = hasPermission(modifyPerms)(CreateAdmin);
  const EditComponent = hasPermission(modifyPerms)(EditAdmin);

  return (
    <Router>
      <ListComponent
        path="/"
        title={listTitle}
        routePath={routePath}
        apiPath={apiPath}
        modifyPerms={modifyPerms}
        headerItems={headerItems}
        rowItems={rowItems}
      />
      <CreateComponent
        path="/nuevo"
        title={createTitle}
        listTitle={listTitle}
        routePath={routePath}
        apiPath={apiPath}
        FormComponent={FormComponent}
      />
      <EditComponent
        path="/:id"
        title={editTitle}
        listTitle={listTitle}
        displayItem={displayItem}
        routePath={routePath}
        apiPath={apiPath}
        FormComponent={FormComponent}
      />
      <NotFoundPage default />
    </Router>
  )
}