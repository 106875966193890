import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useLazyQuery } from 'api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { Formik, Form, ApiErrorMessage } from 'components/ui';
import { formatPacienteDetallado, validateLength } from 'helpers';
import { TIPO_CONTACTO } from '../../constants';

const getInitialValues = ({ paciente, contacto }) => ({
  paciente: contacto ? getPacienteOtro(paciente, contacto) : null,
  fechaContacto: contacto ? contacto.fechaContacto : '',
  lugar: contacto ? contacto.lugar : '',
  tipo: contacto ? contacto.tipo?.toString() : '',
});

function ContactoModal ({ open, onClose, paciente, contacto, error, onSubmit }) {
  const [buscarPacientes] = useLazyQuery('/pacientes');

  const initialValues = getInitialValues({ paciente, contacto });

  const handleFormSubmit = async (input, formik) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { paciente: pacienteOtro, ...contactoInput } = values;
    if (contacto) {
      if (contacto.idPacienteOrigen === paciente.id) {
        contactoInput.idPacienteOrigen =  paciente.id;
        contactoInput.idPacienteDestino = pacienteOtro.id;
      } else {
        contactoInput.idPacienteOrigen =  pacienteOtro.id;
        contactoInput.idPacienteDestino = paciente.id;
      }
    } else {
      contactoInput.idPacienteOrigen = paciente.id;
      contactoInput.idPacienteDestino = pacienteOtro.id;
    }
    
    await onSubmit(contactoInput);
    formik.resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm(paciente)}
    >
      {({ handleSubmit }) => (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          maxWidth="xl"
        >
          <DialogTitle>{contacto ? 'Editar Contacto' : 'Nuevo Contacto'}</DialogTitle>
          <DialogContent dividers>
            <Form alignItems="center">
              <FormUpdater paciente={paciente} contacto={contacto} />
              <Form.Field xs={12}>
                <Form.Lookup
                  required
                  name="paciente"
                  label="Paciente en contacto estrecho"
                  fluid
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option ? formatPacienteDetallado(option) : ''}
                  filterOptions={(options) => options.filter(option => option.id !== paciente.id)}
                  fetcher={(busqueda) => buscarPacientes({ query: { busqueda }}).then(resp => resp.data.nodes)}
                />
              </Form.Field>
              <Form.Field xs={12} md={4}>
                <Form.Datepicker required name="fechaContacto" label="Fecha contacto" fluid InputLabelProps={{ shrink: true }} />
              </Form.Field>
              <Form.Field xs={12} md={4}>
                <Form.TextField name="lugar" label="Lugar" fluid validate={validateLength(0, 500)} />
              </Form.Field>
              <Form.Field xs={12} md={4}>
                <Form.Select name="tipo" label="Relación" required>
                  {_.toPairs(TIPO_CONTACTO).map(([value, text]) => (
                    <MenuItem key={value} value={value}>{text}</MenuItem>
                  ))}
                </Form.Select>
              </Form.Field>

              <Form.Field xs={12}>
                {error && <ApiErrorMessage error={error} />}
              </Form.Field>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">Cancelar</Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>Guardar</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

function FormUpdater({ paciente, contacto }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ paciente, contacto }))
  }, [contacto]);

  return null;
}

function getPacienteOtro(paciente, contacto) {
  return contacto.idPacienteOrigen === paciente.id ? contacto.pacienteDestino : contacto.pacienteOrigen;
}

const validateForm = (paciente) => (values) => {
  const errors = {};

  if (!values.paciente) {
    errors.paciente = 'El paciente es obligatorio';
  } else if (values.paciente.id === paciente.id) {
    errors.paciente = 'No puede elegir el mismo paciente';
  }
  if (!values.fechaContacto) {
    errors.fechaContacto = 'La fecha es obligatoria';
  } else if (moment(values.fechaContacto).isAfter()) {
    errors.fechaContacto = 'La fecha no es válida';
  }
  if (!values.tipo) {
    errors.tipo = 'La relación es obligatoria';
  }
  
  return errors;
}

ContactoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paciente: PropTypes.object.isRequired,
  contacto: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactoModal;