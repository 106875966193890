import React, { useState } from 'react';
import { makeStyles, Avatar, Container, Typography } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { Logo } from 'components/ui';
import { useAuth, isUnauthenticated } from '../auth';
import { useApi } from '../api';
import LoginForm from '../components/login/LoginForm';

function LoginPage () {
  const classes = useStyles();
  const { auth } = useAuth();
  const callApi = useApi();
  const [error, setError] = useState(null);

  const handleLogin = async ({ rut, clave }) => {
    try {
      if (clave === '')
        clave = ' ' // Bypass passport validation
      const { data, error } = await callApi('/auth/login', { method: 'post', body: { rut, clave } });
      setError(error);
      if (data && data.access_token) {
        auth.login(data.access_token);
        window.location.href = '/';
      }
    } catch(err) {}
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay}>
        <Logo className={classes.logo} />
        <Container component="main" maxWidth="xs" className={classes.container}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlined />
            </Avatar>
          </div>
          <Typography component="h1" variant="h5" align="center">Ingresar</Typography>
          <LoginForm
            onLogin={handleLogin}
            error={error}
          />
          
        </Container>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: '#c2e4f4',
    height: '120vh',
    textAlign: 'center',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '120vh',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    paddingTop: theme.spacing(2),
  },
  logo: {
    height: 140,
    margin: theme.spacing(4),
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    textAlign: 'right',
  }
}));

export default isUnauthenticated(LoginPage);