import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';
import { TextArea as MuiTextArea } from '../TextArea';

export function TextArea (props) {
  const [field, meta] = useField(props)
  const hasError = meta.touched && !!meta.error;
  const textProps = _.omit(props, ['validate'])

  return (
    <MuiTextArea
      {...field}
      {...textProps}
      value={field.value || ''}
      // error={hasError}
      // helperText={hasError && meta.error}
    />
  );
}