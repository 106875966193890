import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage, Loader } from 'components/ui';
import { useQuery } from 'api';

function RolForm ({ rol, error, onSubmit }) {
  const { data: permisos, loading } = useQuery('/permisos');
    
  if (loading) {
    return <Loader loading />
  }

  const initialValues = {
    nombre: rol ? rol.nombre : '',
    vigente: rol ? rol.vigente : true,
    permisos: rol ? _.chain(rol.permisos).map(perm => [perm.id, true]).fromPairs().value() : {},
  };

  const handleSubmit = ({ nombre, vigente, permisos }) => {
    const values = { nombre, vigente };
    values.permisos = _.chain(permisos).pickBy(val => val === true).keys().map(id => Number(id)).value();
      
    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form justify="flex-start">
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={6} align="left">
            <Form.TextField required name="nombre" label="Nombre" />
          </Form.Field>
          <Form.Field xs={6} align="left">
            <Form.Checkbox required name="vigente" label="Activo" />
          </Form.Field>

          <Form.Field xs={12} md={8} lg={6} align="left">
            <Typography variant="subtitle1">Permisos</Typography>
            <List>
              {(permisos || []).map((perm) => (
                <ListItem key={perm.id}>
                  <ListItemText primary={perm.descripcion} />
                  <ListItemSecondaryAction>
                    <Form.Switch name={`permisos.${perm.id}`} edge="end" />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.nombre) {
    errors.rut = 'El nombre es obligatorio'
  }
  
  return errors;
}

RolForm.propTypes = {
  rol: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default RolForm;