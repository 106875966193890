
export function validateLength(min, max) {
  return (value) => {
    if (value) {
      if (value.length < min) {
        return `Debe tener al menos ${min} caracteres`;
      }
      if (max && value.length > max) {
        return `No puede tener más de ${max} caracteres`;
      }
    }
  }
}

export function validateUrl(url) {
  const regex = /^([a-z][a-z0-9*\-.]*):\/\/(?:(?:(?:[\w.\-+!$&'()*+,;=]|%[0-9a-f]{2})+:)*(?:[\w.\-+%!$&'()*+,;=]|%[0-9a-f]{2})+@)?(?:(?:[a-z0-9\-.]|%[0-9a-f]{2})+|(?:\[(?:[0-9a-f]{0,4}:)*(?:[0-9a-f]{0,4})\]))(?::[0-9]+)?(?:[/|?](?:[\w#!:.?+=&@!$'~*,;/()[\]-]|%[0-9a-f]{2})*)?$/i
  if (url && !regex.test(url)){
    return 'No es una url válida';
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  if (email && !re.test(email)) {
    return 'No es un email válido';
  }
}

export function validatePassword(newPassword, oldPassword) {
  if (newPassword.trim().length < 6) {
    return 'La contraseña debe tener al menos 6 carecteres'
  }
  if (newPassword.replace(/\d/g, '').length === 0) {
    return 'La contraseña debe ser alfanumérica'
  }
  if (oldPassword && newPassword.trim() === oldPassword.trim()) {
    return 'Debe ingresar una contraseña nueva'
  }
}