import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, makeStyles, Hidden, Typography } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Logo } from 'components/ui';

import UserMenu from './UserMenu';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
  },
  toolBar: {
  },
  logo: {
    height: 50,
  },
  menuButton: {
    color: theme.palette.primary.main,
  },
  title: {
    color: 'black',
    fontSize: 16,
    marginLeft: 20,
  },
  grow: {
    flexGrow: 1,
  }
}));

function AppNavbar({ usuario, onMenuClick, children }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Hidden smUp implementation="css">
          <IconButton edge="start" aria-label="open drawer" className={classes.menuButton} onClick={onMenuClick}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Logo className={classes.logo} />
        <Typography className={classes.title}>Sistema de Seguimiento de Pacientes</Typography>
        <div className={classes.grow} />
        <div>
          {children}
          {usuario && <UserMenu usuario={usuario} />}
        </div>
      </Toolbar>
    </AppBar>
  )
}

AppNavbar.propTypes = {
  usuario: PropTypes.object,
  onMenuClick: PropTypes.func,
  children: PropTypes.node,
}

export default AppNavbar;
