import React from 'react';
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { Chart } from './Chart';

export function NumberStat({ title, value, valueProps }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h2" component="h2" {...valueProps}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  )
}

export function ChartStat({ title, height, ...props }) {
  const classes = useStyles();

  return (
    <Card className={classes.root} style={{ height }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom className={classes.title}>
          {title}
        </Typography>
        <Chart {...props} />
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
});
