import 'whatwg-fetch';
import { useState, useEffect } from 'react';
import { useAuth } from '../auth';

const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;

export const useApi = () => {
  const { auth } = useAuth();
  
  const callApi = (path, config) => fetchApi(auth, path, config);
  return callApi;
}

const makeRefetcher = fetcher => (cfg = {}) => fetcher({
  ...cfg,
  headers: {
    ...cfg.headers,
    'Cache-Control': 'no-cache',
  }
})

export const useLazyQuery = (path, config={}, initialState=null) => {
  const callApi = useApi();
  const fetcher = (cfg) => callApi(path, { ...config, ...cfg });
  const [fetch, { data, loading, loaded, error }] = useLoadingState(fetcher, initialState);

  const refetch = makeRefetcher(fetch);

  return [fetch, { data, loading, loaded, error, refetch }];
}

export const useQuery = (path, config={}, initialState=null) => {
  const callApi = useApi();
  const fetcher = (cfg) => callApi(path, { ...config, ...cfg });
  const [fetch, { data, loading, loaded, error }] = useLoadingState(fetcher, initialState);

  const refetch = makeRefetcher(fetch);

  useEffect(() => {
    fetch();
  }, []);

  return { data, loading, loaded, error, refetch };
}

export const useMutation = useLazyQuery;

const useLoadingState = (fetcher, initialState=null) => {
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetch = (...args) => {
    setLoading(true);
    setLoaded(false);
    return fetcher(...args)
    .then(res => {
      if (res.data) {
        setData(res.data);
      }
      if (res.error) {
        setError(res.error);
      }
      setLoading(false);
      setLoaded(true);
      return res;
    });
  }

  return [fetch, { data, error, loading, loaded }];
}

function fetchApi (auth, path, config={}) {
  const url = new URL(apiBaseUrl + path);
  config.method = config.method ? config.method.toUpperCase() : 'GET';
  config.mode = 'cors';
  config.headers = {
    ...config.headers,
    'Accept': 'application/json',
  };

  if (auth.isAuthenticated) {
    config.headers['Authorization'] = `Bearer ${auth.token}`;
  }

  if (config.body) {
    config.headers['Content-Type'] = 'application/json';
    config.body = JSON.stringify(config.body);
  }
  if (config.query) {
    Object.keys(config.query).forEach(key => url.searchParams.append(key, config.query[key]));
  }
  
  return fetch(url, config)
  .then(res => {
    if (config.type === 'text')
      return res.text();
    else if (config.type === 'blob')
      return res.blob();
    else
      return res.json();
  })
  .then(body => {
    if (config.type && config.type !== 'json') {
      return body;
    } else if (body.statusCode >= 400) {
      return { error: body };
    } else {
      return { data: body }
    }
  })
}