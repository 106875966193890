import React from 'react';
import Admin from 'components/admin';
import EnfermedadForm from 'components/listados/EnfermedadForm';
import { PERMISO } from '../../constants';

export default function EnfermedadesPages() {
  return (
    <Admin
      createTitle="Nueva Enfermedad"
      listTitle="Enfermedades"
      editTitle="Editar Enfermedad"
      routePath="/admin/enfermedades"
      apiPath="/enfermedades"
      FormComponent={EnfermedadForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.EnfermedadesVer]}
      modifyPerms={[PERMISO.EnfermedadesModificar]}
      headerItems={['ID', 'Nombre', 'Días Cuarentena']}
      rowItems={row => [row.id, row.nombre, row.diasCuarentena]}
    />
  )
}
