import React, { useState } from 'react';
import { TableRow, TableCell, Grid } from '@material-ui/core';
import { Add as AddIcon, Edit, Check, Clear } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, ActionsButton, Breadcrumbs, Title, FilterGrid, TextField, useDelayedSearch } from 'components/ui';
import { useQuery } from 'api';
import { formatDate } from 'helpers/format';
import { navigate } from '@reach/router';
import { formatRut } from 'helpers';
import { PERMISO } from '../../constants';

function ListadoUsuarios() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10, busqueda: '' });
  
  const canEdit = auth.hasPermission([PERMISO.UsuariosModificar]);

  const { data: usuarios, loading, refetch } = useQuery('/usuarios');
  
  const search = useDelayedSearch(newState => refetch({ query: newState }));
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  const onChangeBusqueda = (e) => {
    const newState = { ...state, busqueda: e.target.value, page: 1 };
    setState(newState);
    search(newState);
  }
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'usuarios', title: 'Funcionarios', to: '/admin/funcionarios', active: true },
      ]} />
      <Title>Funcionarios</Title>
      <Loader loading={loading} />

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fluid
            name='busqueda'
            label='Buscar'
            value={state.busqueda}
            onChange={onChangeBusqueda}
          />
        </Grid>
      </FilterGrid>
      
      <DataTable
        data={usuarios}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <UsuarioHeader />}
        renderRow={(usuario) => (
          <UsuarioRow
            key={usuario.id}
            usuario={usuario}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/funcionarios/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function UsuarioHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Nombres</TableCell>
      <TableCell>Apellidos</TableCell>
      <TableCell>Departamento</TableCell>
      <TableCell>Rol</TableCell>
      <TableCell>Activo</TableCell>
      <TableCell>Fecha Registro</TableCell>
      <TableCell>Fecha Modificación</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function UsuarioRow({ usuario, canEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{usuario.id}</CompactTableCell>
      <CompactTableCell>{formatRut(usuario.rut)}</CompactTableCell>
      <CompactTableCell>{usuario.nombres}</CompactTableCell>
      <CompactTableCell>{usuario.apellidos}</CompactTableCell>
      <CompactTableCell>{usuario.departamento.nombre}</CompactTableCell>
      <CompactTableCell>{usuario.rol.nombre}</CompactTableCell>
      <CompactTableCell>{usuario.activo ? <Check color='primary'/> : <Clear color='error' />}</CompactTableCell>
      <CompactTableCell>{formatDate(usuario.fechaRegistro)}</CompactTableCell>
      <CompactTableCell>{formatDate(usuario.fechaModificacion)}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/funcionarios/${usuario.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.UsuariosVer])(ListadoUsuarios);
