import React, { forwardRef } from 'react';
import ReactToPrint from 'react-to-print';
import { IconButton, makeStyles } from '@material-ui/core';
import { Print as PrintIcon } from '@material-ui/icons';

const PrintButton = forwardRef((props, ref) => {
  const classes = useStyles();
  
  return (
    <ReactToPrint
      trigger={() => (
        <IconButton aria-label="imprimir" className={classes.toolbarButton}>
          <PrintIcon />
        </IconButton>
      )}
      content={() => ref.current}
    />
  );
});

const useStyles = makeStyles(theme => ({
  toolbarButton: {
    marginRight: theme.spacing(2),
  }
}));


export default PrintButton;
