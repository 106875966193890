import React from 'react';
import Admin from 'components/admin';
import SistemaSaludForm from 'components/listados/SistemaSaludForm';
import { PERMISO } from '../../constants';

export default function CentroSaludPages() {
  return (
    <Admin
      createTitle="Nuevo Sistema de Salud"
      listTitle="Sistemas de Salud"
      editTitle="Editar Sistema de Salud"
      routePath="/admin/sistemas-salud"
      apiPath="/sistemas-salud"
      FormComponent={SistemaSaludForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
