import React, { useEffect, useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import MuestraBacForm from 'components/muestraBac/MuestraBacForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';

function EditarMuestraBac({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);

  const { data: muestraBac, loading, refetch } = useQuery(`/muestras-bac/${id}`);
  const [editarMuestraBac, { loading: posting, error }] = useMutation(`/muestras-bac/${id}`, { method: 'PATCH' });

  const handleSubmit = async values => {
    const { error } = await editarMuestraBac({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'muestras', title: 'Muestras BAC', to: '/admin/muestras-bac' },
        { key: 'actual', title: `#${id}`, to: `/admin/muestras-bac/${id}`, active: true },
      ]} />
      <Title>Editar Muestra BAC</Title>
      <Loader loading={loading || posting} />

      <MuestraBacForm
        muestra={muestraBac}
        error={error}
        onSubmit={handleSubmit}
      />

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.MuestrasBACModificar])(EditarMuestraBac);
