import React from 'react';
import _ from 'lodash';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import { ErrorMessage as Message } from '../ErrorMessage';

export const ErrorMessage = ({ name, ...props }) => {
  return (
    <FormikErrorMessage name={name}>
      {message => _.isString(message) ? <Message {...props}>{message}</Message> : null}
    </FormikErrorMessage>
  )
}