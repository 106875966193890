import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { Hidden, Drawer, makeStyles } from '@material-ui/core';
import { Build, PieChart, Assessment, People, Assignment } from '@material-ui/icons';
import { AuthContext, hasPermissionFn } from 'auth';
import Menu from './SidebarMenu';

import './Sidebar.css';
import { PERMISO } from '../../constants';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    marginTop: theme.spacing(2),
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    minHeight: theme.spacing(8),
  },
}));

function Sidebar({ open, toggleDrawer }) {
  const { user } = React.useContext(AuthContext);
  const classes = useStyles();
  
  let items = [
    { key: 'dashboard', text: 'Estadísticas', icon: <PieChart />, to: '/' },
    { key: 'mantencion', text: 'Mantención', icon: <Build/>, items: [
      { key: 'centro-asistencial', text: 'Centros Asistenciales', to: '/admin/centros-asistenciales', perms: [PERMISO.ListadosVer] },
      { key: 'centros-salud', text: 'Centros de Salud', to: '/admin/centros-salud', perms: [PERMISO.ListadosVer] },
      { key: 'criterios-muestra', text: 'Criterios de Toma de Muestras', to: '/admin/criterios-toma-muestras', perms: [PERMISO.ListadosVer] },
      { key: 'departamentos', text: 'Departamentos', to: '/admin/departamentos', perms: [PERMISO.DepartamentosVer] },
      { key: 'enfermedades', text: 'Enfermedades', to: '/admin/enfermedades', perms: [PERMISO.EnfermedadesVer] },
      { key: 'estados-ayuda', text: 'Estados de Ayuda', to: '/admin/estados-ayuda', perms: [PERMISO.ListadosVer] },
      { key: 'estados-paciente', text: 'Estados de Paciente', to: '/admin/estados-paciente', perms: [PERMISO.ListadosVer] },
      { key: 'estrategias-muestra', text: 'Estrategias de Toma de Muestras', to: '/admin/estrategias-toma-muestras', perms: [PERMISO.ListadosVer] },
      { key: 'usuarios', text: 'Funcionarios', to: '/admin/funcionarios', perms: [PERMISO.UsuariosVer] },
      { key: 'residencias', text: 'Residencias Sanitarias', to: '/admin/residencias-sanitarias', perms: [PERMISO.RolesVer] },
      { key: 'roles', text: 'Roles', to: '/admin/roles', perms: [PERMISO.RolesVer] },
      { key: 'sintomas', text: 'Síntomas', to: '/admin/sintomas', perms: [PERMISO.ListadosVer] },
      { key: 'sistemas-salud', text: 'Sistemas de Salud', to: '/admin/sistemas-salud', perms: [PERMISO.ListadosVer] },
      { key: 'tipos-ayuda', text: 'Tipos de Ayudas', to: '/admin/tipos-ayuda', perms: [PERMISO.ListadosVer] },
      { key: 'tipos-muestra', text: 'Tipos de Muestras', to: '/admin/tipos-muestra', perms: [PERMISO.ListadosVer] },
      { key: 'laboratorios', text: 'Tomas de Muestras', to: '/admin/tomas-muestras', perms: [PERMISO.ListadosVer] },
    ]},
    { key: 'pacientes', text: 'Pacientes', icon: <People />, to: '/admin/pacientes', perms: [PERMISO.PacientesVer] },
    { key: 'muestras-bac', text: 'Muestras BAC', icon: <Assignment />, to: '/admin/muestras-bac', perms: [PERMISO.MuestrasBACVer] },
    { key: 'reportes', text: 'Reportes', icon: <Assessment/>, items: [
      { key: 'reporte-usuarios', text: 'Funcionarios', to: '/reportes/funcionarios', perms: [PERMISO.ReportesUsuarios] },
      { key: 'reporte-pacientes', text: 'Pacientes', to: '/reportes/pacientes', perms: [PERMISO.ReportesUsuarios] },
    ]},
  ];
    
  items = items.map((item) => {
    if (item.perms && !hasPermissionFn(user, item.perms)) {
      return null;
    }
    if (item.items) {
      item.items = item.items.filter(subitem => !subitem.perms || hasPermissionFn(user, subitem.perms));
      if (item.items.length === 0) {
        return null;
      }
    }
    return item;
  }).filter(item => item !== null);

  const drawer = (
    <div>
      <Location>
        {({ location }) => (
          <React.Fragment>
            <Menu items={items} location={location} />
          </React.Fragment>
        )}
      </Location>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar}></div>
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
}

export default Sidebar;