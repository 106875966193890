import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { isAuthenticated } from 'auth';
import ReporteDiario from './ReporteDiario';
import Evolucion from './Evolucion';

function Estadisticas() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            variant="fullWidth"
            onChange={(e, newTab) => setActiveTab(newTab)}
          >
            <Tab label="Reporte Diario" />
            <Tab label="Evolución" />
          </Tabs>
        </Grid>
        <Grid item xs={12}> 
          {activeTab === 0 && <ReporteDiario />}
          {activeTab === 1 && <Evolucion />}
        </Grid>
      </Grid>
    </div>
  );
}

export default isAuthenticated(Estadisticas);
