import React from 'react';
import _ from 'lodash';
import { useField } from 'formik';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { TextField } from '../TextField';

const TEXT_FIELD_PROPS = ['label', 'placeholder', 'fluid'];

export function Autocomplete(props) {
  const [field, meta] = useField(props)
  const textProps = _.pick(props, TEXT_FIELD_PROPS);
  const autocompleteProps = _.omit(props, TEXT_FIELD_PROPS);
  
  return (
    <MuiAutocomplete
      {...field}
      {...autocompleteProps}
      onChange={(e, value) => {
        e.target.value = value;
        field.onChange({ target: { name: field.name, value } });
        if (props.onChange) { props.onChange(e, value); }
      }}
      renderInput={props.renderInput || (params => (
        <TextField
          {...params}
          {...textProps}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      ))}
    />
  );
}
