import React from 'react';

const styles = {
  color: 'red',
  fontSize: 14,
};

export function ErrorMessage ({ children }) {
  return (
    <p style={styles}>
      {children}
    </p>
  );
}

export function ApiErrorMessage({ error }) {
  if (error && error.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  } else if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }
  return null;
}
