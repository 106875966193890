import React from 'react';
import { TextField as MuiTextField, InputLabel, Input, InputAdornment, IconButton, FormHelperText, Tooltip } from '@material-ui/core';
import { StyledFormControl } from './styledComponents';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export function TextField ({ fluid, tooltip, ...props }) {
  let textField = <MuiTextField {...props} />;

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>{textField}</Tooltip>
    )
  }

  return (
    <StyledFormControl fluid={fluid}>
      {textField}
    </StyledFormControl>
  );
}

export function PasswordField ({ fluid, label, helperText, ...props }) {
  const [visible, setVisible] = React.useState(false);

  const handleClickShowPassword = () => setVisible(value => !value);
  const handleMouseDownPassword = e => e.preventDefault();

  return (
    <StyledFormControl fluid={fluid}>
      {label && <InputLabel>{label}</InputLabel>}
      <Input
        {...props}
        type={visible ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {helperText && <FormHelperText id={props.id} error={props.error}>{helperText}</FormHelperText>}
    </StyledFormControl>
  )
}