import React from 'react';
import { Router } from '@reach/router';

import ListadoPacientes from './ListadoPacientes';
import CrearPaciente from './CrearPaciente';
import EditarPaciente from './EditarPaciente';
import CrearFicha from './CrearFicha';
import EditarFicha from './EditarFicha';
import NotFoundPage from '../NotFoundPage';

export default function PacientePages() {
  return (
    <Router>
      <ListadoPacientes path="/" />
      <CrearPaciente path="/nuevo" />
      <EditarPaciente path="/:id" />
      <EditarFicha path="/:idPaciente/fichas/:idFicha" />
      <CrearFicha path="/:id/fichas/nuevo" />
      <NotFoundPage default />
    </Router>
  )
}