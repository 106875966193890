import React from 'react';
import { InputAdornment, Button, makeStyles } from '@material-ui/core';
import { AccountCircle, VpnKey } from '@material-ui/icons';
import { Formik, Form, ApiErrorMessage } from 'components/ui';
import { validateRut, cleanRut } from 'helpers/usuario';

function LoginForm({ error, onLogin }) {
  const classes = useStyles();

  const validate = (values) => {
    let errors = {};

    if (!values.rut || !validateRut(values.rut)) {
      errors.rut = 'El RUT ingresado no es válido';
    }

    return errors;
  }

  const onSubmit = (values) => {
    const rut = cleanRut(values.rut);
    const clave = values.clave.trim();
    return onLogin({ rut, clave });
  }

  return (
    <Formik
      initialValues={{ rut: '', clave: '' }}
      onSubmit={onSubmit}
      validate={validate}
    >
      <Form className={classes.form}>
        <Form.TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          size="small"
          id="rut"
          label="RUT"
          name="rut"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <Form.TextField
          variant="outlined"
          margin="normal"
          // required
          fullWidth
          size="small"
          name="clave"
          label="Clave"
          type="password"
          id="password"
          autoComplete="current-password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKey />
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Ingresar
        </Button>

        {error && <ApiErrorMessage error="El rut y la contraseña no coinciden" />}
      </Form>
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    // marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default LoginForm;
