import React from 'react';
import { Link as RouterLink } from '@reach/router';
import { Link as MuiLink } from '@material-ui/core';

// const MUI_LINK_PROPS = ['color', 'underline', 'variant'];

export function Link(props) {
  // const muiProps = _.pick(props, MUI_LINK_PROPS);
  // const routerProps = _.omit(props, MUI_LINK_PROPS);

  return (
    <MuiLink {...props} component={RouterLink} />
  );
}
