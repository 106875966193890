
export const PERMISO = {
  // Mantención
  UsuariosVer: 'usuarios.ver',
  UsuariosModificar: 'usuarios.modificar',
  DepartamentosVer: 'departamentos.ver',
  DepartamentosModificar: 'departamentos.modificar',
  RolesVer: 'roles.ver',
  RolesModificar: 'roles.modificar',
  ResidenciaSanitariaVer: 'residencias-sanitarias.ver',
  ResidenciaSanitariaModificar: 'residencias-sanitarias.modificar',
  EnfermedadesVer: 'enfermedades.ver',
  EnfermedadesModificar: 'enfermedades.modificar',
  ListadosVer: 'listados.ver',
  ListadosModificar: 'listados.modificar',
  // Pacientes
  PacientesVer: 'pacientes.ver',
  PacientesModificar: 'pacientes.modificar',
  // Fichas
  FichasVer: 'fichas.ver',
  FichasModificar: 'fichas.modificar',
  // Seguimientos
  SeguimientosVer: 'seguimientos.ver',
  SeguimientosModificar: 'seguimientos.modificar',
  // Contactos
  ContactosVer: 'contactos.ver',
  ContactosModificar: 'contactos.modificar',
  // Muestras BAC
  MuestrasBACVer: 'muestrasBAC.ver',
  MuestrasBACModificar: 'muestrasBAC.modificar',
  // Reportes
  ReportesUsuarios: 'reportes.usuarios',
  ReportesPacientes: 'reportes.pacientes',
}

export const ESTADOS = {
  0: 'Pendiente',
  1: 'Negativo',
  2: 'Positivo',
  3: 'No Concluyente'
}

export const TIPOS_SEGUIMIENTOS = {
  1: 'Visita presencial',
  2: 'Llamado telefónico',
  3: 'Mensaje',
  4: 'Correo electrónico',
}

export const TIPO_CONTACTO = {
  1: 'Núcleo familiar',
  2: 'Pariente',
  3: 'Amigo',
  4: 'Trabajo',
  5: 'Ocasional',
}
