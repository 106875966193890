import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { ConfirmDialog } from './ConfirmDialog';

export function ActionsButton({ items }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {items.map(({ key, text, icon, onClick, disabled, confirmText }) => {
          if (confirmText) {
            return (
              <ConfirmDialog key={key} text={confirmText} onConfirm={onClick}>
                {(openModal) => (
                  <MenuItem
                    disabled={disabled}
                    onClick={() => {
                      openModal();
                      handleClose();
                    }}
                  >
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} />
                  </MenuItem>
                )}
              </ConfirmDialog>
            )
          } else {
            return (
              <MenuItem
                key={key}
                disabled={disabled}
                onClick={() => {
                  onClick();
                  handleClose();
                }}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </MenuItem>
            );
          }
        })}
      </Menu>
    </React.Fragment>
  )
}

ActionsButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    confirmText: PropTypes.string,
  })).isRequired,
}
