import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useLazyQuery, useQuery } from 'api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@material-ui/core';
import { Formik, Form, ApiErrorMessage } from 'components/ui';
import { formatUsuario } from 'helpers';

const getInitialValues = ({ ficha, muestra }) => ({
  ficha: muestra ? muestra.ficha : ficha,
  idTipoMuestra: muestra ? muestra.idTipoMuestra : '',
  fechaMuestra: muestra ? muestra.fechaMuestra : moment().toISOString(),
  fechaResultado: muestra ? muestra.fechaResultado : '',
  estado: muestra ? `${muestra.estado}` : '0',
  idLaboratorio: muestra?.idLaboratorio || '',
  idCriterioMuestra: muestra?.idCriterioMuestra || '',
  idEstrategiaMuestra: muestra?.idEstrategiaMuestra || '',
  fechaNotificacion: muestra?.fechaNotificacion || '',
  usuarioNotificacion: muestra?.usuarioNotificacion || null,
  idTipoNotificacion: muestra?.idTipoNotificacion || '',
})

function MuestraModal ({ open, onClose, ficha, muestra, error, onSubmit }) {
  const [buscarUsuarios] = useLazyQuery('/usuarios');
  const { data: tiposMuestra } = useQuery('/tipos-muestra', {}, []);
  const { data: laboratorios } = useQuery('/laboratorios', {}, []);
  const { data: criteriosMuestra } = useQuery('/criterios-muestra', {}, []);
  const { data: estrategiasMuestra } = useQuery('/estrategias-muestra', {}, []);

  const initialValues = getInitialValues({ ficha, muestra });

  const handleFormSubmit = async (input, formik) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { ficha, usuarioNotificacion, ...muestraValues } = values;
    muestraValues.idFicha = ficha.id;
    muestraValues.idUsuarioNotificacion = usuarioNotificacion?.id || null;
    
    await onSubmit(muestraValues);
    formik.resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm(ficha)}
    >
      {({ values, handleSubmit }) => (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          maxWidth="xl"
        >
          <DialogTitle>{muestra ? 'Editar Muestra' : 'Nueva Muestra'}</DialogTitle>
          <DialogContent dividers>
            <Form alignItems="center">
              <FormUpdater ficha={ficha} muestra={muestra} />
              <Form.Field xs={12} md={3}>
                <Form.Select required name="idTipoMuestra" label="Tipo" fluid align="left">
                  {tiposMuestra.map(tm => <MenuItem key={tm.id} value={tm.id}>{tm.nombre}</MenuItem>)}
                </Form.Select>
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.Datepicker required name="fechaMuestra" label="Fecha muestra" fluid InputLabelProps={{ shrink: true }} />
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.Select name="estado" label="Resultado" fluid align="left">
                  <MenuItem value="0">Pendiente</MenuItem>
                  <MenuItem value="1">Negativo</MenuItem>
                  <MenuItem value="2">Positivo</MenuItem>
                  <MenuItem value="3">No Concluyente</MenuItem>
                </Form.Select>
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.Datepicker name="fechaResultado" label="Fecha resultado" fluid InputLabelProps={{ shrink: true }} />
              </Form.Field>

              <Form.Field xs={12} md={4}>
                <Form.Select name="idLaboratorio" label="Toma de Muestra" fluid align="left">
                  {laboratorios.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
                </Form.Select>
              </Form.Field>
              <Form.Field xs={12} md={4}>
                <Form.Select name="idCriterioMuestra" label="Criterio" fluid align="left">
                  {criteriosMuestra.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
                </Form.Select>
              </Form.Field>
              <Form.Field xs={12} md={4}>
                <Form.Select name="idEstrategiaMuestra" label="Estrategia" fluid align="left">
                  {estrategiasMuestra.map(item => <MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)}
                </Form.Select>
              </Form.Field>

              {values.estado !== '0' && (
                <>
                  <Form.Field xs={12}>
                    <Typography variant="subtitle1">Notificación</Typography>
                  </Form.Field>
                  <Form.Field xs={12} md={3}>
                    <Form.Datepicker name="fechaNotificacion" label="Fecha" fluid InputLabelProps={{ shrink: true }} />
                  </Form.Field>
                  <Form.Field xs={12} md={5}>
                    <Form.Lookup
                      required
                      name="usuarioNotificacion"
                      label="Funcionario"
                      fluid
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option ? formatUsuario(option) : ''}
                      fetcher={(busqueda) => buscarUsuarios({ query: { busqueda }}).then(resp => resp.data.nodes)}
                    />
                  </Form.Field>
                  <Form.Field xs={12} md={3}>
                    <Form.Select required name="tipoNotificación" label="Tipo de Contacto" fluid align="left">
                      <MenuItem value="1">Llamado telefónico</MenuItem>
                      <MenuItem value="2">Presencial</MenuItem>
                      <MenuItem value="3">Mensaje</MenuItem>
                      <MenuItem value="4">Correo electrónico</MenuItem>
                      <MenuItem value="5">Otro</MenuItem>
                    </Form.Select>
                  </Form.Field>
                </>
              )}

              <Form.Field xs={12}>
                {error && <ApiErrorMessage error={error} />}
              </Form.Field>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">Cancelar</Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>Guardar</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

function FormUpdater({ ficha, muestra }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ ficha, muestra }))
  }, [muestra]);

  return null;
}

const validateForm = (ficha) => (values) => {
  const errors = {};

  if (!values.idTipoMuestra) {
    errors.idTipoMuestra = 'El tipo de muestra es obligatorio';
  }
  if (!values.fechaMuestra) {
    errors.fechaMuestra = 'La fecha de muestra es obligatoria';
  } else if (moment(values.fechaMuestra).isAfter()) {
    errors.fechaMuestra = 'La fecha de muestra no es válida';
  }
  if (!values.estado) {
    errors.estado = 'El estado es obligatorio';
  } else if (values.estado !== '0' && !values.fechaResultado) {
    errors.fechaResultado = 'La fecha de resultado es obligatoria';
  }
  if (values.fechaResultado && values.fechaMuestra && moment(values.fechaResultado).diff(moment(values.fechaMuestra), 'days') < 0) {
    errors.fechaResultado = 'No puede ser anterior a la fecha de muestra';
  }

  return errors;
}

MuestraModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ficha: PropTypes.object.isRequired,
  muestra: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default MuestraModal;