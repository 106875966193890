import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateLength } from 'helpers';

function CentroSaludForm ({ item, error, onSubmit }) {

  const initialValues = {
    nombre: item ? item.nombre : '',
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form alignItems="left">
          <Form.Field xs={12}>
            <Form.TextField required name="nombre" label="Nombre" validate={validateLength(0, 100)} />
          </Form.Field>
          
          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.nombre) {
    errors.nombre = 'El nombre es obligatorio'
  }
  
  return errors;
}

CentroSaludForm.propTypes = {
  item: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default CentroSaludForm;