import React, { useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import { useMutation, useQuery } from 'api';

export default function EditAdmin({ id, title, listTitle, displayItem, routePath, apiPath, FormComponent }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: item, loading } = useQuery(`${apiPath}/${id}`);
  const [editFn, { loading: posting, error }] = useMutation(`${apiPath}/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editFn({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: listTitle, to: routePath },
        { key: 'actual', title: item ? displayItem(item) : id, to: `${routePath}/${id}`, active: true },
      ]} />
      <Title>{title}</Title>
      <Loader loading={loading || posting} />

      {item && 
        <FormComponent
          item={item}
          error={error}
          onSubmit={handleSubmit}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

