import React from 'react';
import Admin from 'components/admin';
import EstadoPacienteForm from 'components/listados/EstadoPacienteForm';
import { PERMISO } from '../../constants';

export default function CentroSaludPages() {
  return (
    <Admin
      createTitle="Nuevo Estado de Paciente"
      listTitle="Estados de Paciente"
      editTitle="Editar Estado de Paciente"
      routePath="/admin/estados-paciente"
      apiPath="/estados-paciente"
      FormComponent={EstadoPacienteForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
