import React from 'react';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';

export default function Footer() {
  const classes = useStyles();

  return (
    <footer id="footer" className={classes.container}>
      <Typography>
        Municipalidad de Lautaro {moment().year()}
      </Typography>
    </footer>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.success.main,
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: theme.spacing(10)
  }
}))