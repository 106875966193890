import React from 'react';
import { Snackbar as MuiSnackbar, IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';

export function Snackbar({ title, message, open, setOpen, ...props }) {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') { return; }
    setOpen(false);
  }
  
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert
        {...props}      
        onClose={handleClose}
        elevation={6}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </MuiSnackbar>
  )
}