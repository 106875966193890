import React from 'react';
import { useField, useFormikContext } from 'formik';
import { Lookup as LookupField } from '../Lookup';

export function Lookup(props) {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <LookupField
      {...field}
      {...props}
      blurOnSelect
      onChange={(e, val) => {
        setFieldValue(field.name, val);
        setFieldTouched(field.name, true, false);
        if (props.onChange) {
          props.onChange(e, val);
        }
      }}
      inputProps={{
        error: meta.touched && !!meta.error,
        helperText: meta.touched && meta.error,
      }}
    />
  )
}