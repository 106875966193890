import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell } from 'components/ui';
import { useQuery } from 'api';
import { formatDate, formatDireccion, formatList } from 'helpers/format';
import { navigate } from '@reach/router';
import { ESTADOS, PERMISO } from '../../constants';

function ListadoFichas({ paciente }) {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  
  const canEdit = auth.hasPermission([PERMISO.FichasModificar]);

  const { data: fichas, loading, refetch } = useQuery(`/pacientes/${paciente.id}/fichas`);

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Loader loading={loading} />

      <DataTable
        data={fichas}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <FichaHeader />}
        renderRow={(ficha) => (
          <FichaRow
            key={ficha.id}
            ficha={ficha}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate(`/admin/pacientes/${paciente.id}/fichas/nuevo`)}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function FichaHeader() {
  return (
    <TableRow>
      <TableCell>#</TableCell>
      <TableCell>Caso</TableCell>
      <TableCell>Centro de Salud</TableCell>
      <TableCell>Síntomas</TableCell>
      <TableCell>Último Resultado</TableCell>
      <TableCell>Fecha Última Muestra</TableCell>
      <TableCell>Estado Actual</TableCell>
      <TableCell>Inicio Cuarentena</TableCell>
      <TableCell>Fin Cuarentena</TableCell>
      <TableCell>Dirección Cuarentena</TableCell>
    </TableRow>
  )
}

function FichaRow({ ficha }) {
  return (
    <TableRow hover style={{cursor: 'pointer'}} onClick={() => navigate(`/admin/pacientes/${ficha.idPaciente}/fichas/${ficha.id}`)}>
      <CompactTableCell>{ficha.id}</CompactTableCell>
      <CompactTableCell>{ficha.enfermedad.nombre}</CompactTableCell>
      <CompactTableCell>{ficha.centroSalud?.nombre}</CompactTableCell>
      <CompactTableCell>{formatList(ficha.sintomas)}</CompactTableCell>
      <CompactTableCell>{ESTADOS[ficha.estadoActual]}</CompactTableCell>
      <CompactTableCell>{formatDate(ficha.fechaUltimaMuestra)}</CompactTableCell>
      <CompactTableCell>{ficha.estadoPacienteActual?.nombre}</CompactTableCell>
      <CompactTableCell>{formatDate(ficha.fechaInicioCuarentena)}</CompactTableCell>
      <CompactTableCell>{formatDate(ficha.fechaFinCuarentenaActual)}</CompactTableCell>
      <CompactTableCell>{formatDireccion(ficha.direccionCuarentenaActual)}</CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.FichasVer])(ListadoFichas);
