import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import RolForm from 'components/rol/RolForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearRol() {
  const [crearRol, { loading, error }] = useMutation('/roles', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearRol({ body: values });

    if (!error) {
      navigate('/admin/roles');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'roles', title: 'Roles', to: '/admin/roles' },
        { key: 'actual', title: 'Nuevo rol', to: '/admin/roles/nuevo', active: true },
      ]} />
      <Title>Nuevo Rol</Title>
      <Loader loading={loading} />

      <RolForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.RolesModificar])(CrearRol);
