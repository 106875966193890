import React from 'react';
import { Typography, Container } from '@material-ui/core';

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null }
  }
  
  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
    this.setState({ error });
  }
  
  render() {
    if (this.state.error) {
      return (
        <Container style={{textAlign: 'center', marginTop: 100}}>
          <Typography variant="h2">Error</Typography>

          <Typography>
            Un error ha ocurrido. Puede intentar <a href={window.location.href}>recargar la página</a>.
            De persistir el error, contacte al administrador del sitio.
          </Typography>
        </Container>
      )
    } else {
      return this.props.children;
    }
  }
}

export default ErrorPage;
