import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import MuestraBacForm from 'components/muestraBac/MuestraBacForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearMuestraBac() {
  const [crearMuestraBac, { loading, error }] = useMutation('/muestras-bac', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearMuestraBac({ body: values });

    if (!error) {
      navigate('/admin/muestras-bac');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'muestras', title: 'Muestras BAC', to: '/admin/muestras-bac' },
        { key: 'actual', title: 'Nueva Muestra BAC', to: '/admin/muestras-bac/nuevo', active: true },
      ]} />
      <Title>Nueva Muestra BAC</Title>
      <Loader loading={loading} />

      <MuestraBacForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.MuestrasBACModificar])(CrearMuestraBac);
