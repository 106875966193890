import React, { useState } from 'react';
import moment from 'moment';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, FilterGrid, TextField, CompactTableCell, Breadcrumbs, Title, useDelayedSearch } from 'components/ui';
import { useQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatPaciente, formatDireccion } from 'helpers/format';
import { navigate } from '@reach/router';
import { PERMISO } from '../../constants';

function ListadoPacientes() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10, busqueda: '' });
  
  const canEdit = auth.hasPermission([PERMISO.PacientesModificar]);

  const { data: pacientes, loading, refetch } = useQuery('/pacientes');
    
  const search = useDelayedSearch(newState => refetch({ query: newState }));

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  const onChangeBusqueda = (e) => {
    const newState = { ...state, busqueda: e.target.value, page: 1 };
    setState(newState);
    search(newState);
  }
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'pacientes', title: 'Pacientes', to: '/admin/pacientes', active: true },
      ]} />
      <Title>Pacientes</Title>
      <Loader loading={loading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fluid
            name='busqueda'
            label='Buscar'
            value={state.busqueda}
            onChange={onChangeBusqueda}
          />
        </Grid>
      </FilterGrid>

      <DataTable
        data={pacientes}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <PacienteHeader />}
        renderRow={(paciente) => (
          <PacienteRow
            key={paciente.id}
            paciente={paciente}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/pacientes/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function PacienteHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Nombre Completo</TableCell>
      <TableCell>Edad</TableCell>
      <TableCell>Sexo</TableCell>
      <TableCell>Dirección</TableCell>
      <TableCell>Tel 1</TableCell>
      <TableCell>Tel Contacto</TableCell>
      <TableCell>Sistema Salud</TableCell>
      <TableCell>Fecha Ingreso</TableCell>
      <TableCell>Fecha Modificación</TableCell>
      {/* <TableCell></TableCell> */}
    </TableRow>
  )
}

function PacienteRow({ paciente, canEdit }) {
  const edad = paciente.fechaNacimiento ? moment().diff(moment(paciente.fechaNacimiento), 'years') : '';
  
  return (
    <TableRow hover style={{cursor: 'pointer'}} onClick={() => navigate(`/admin/pacientes/${paciente.id}`)}>
      <CompactTableCell>{paciente.id}</CompactTableCell>
      <CompactTableCell>{formatRut(paciente.rut)}</CompactTableCell>
      <CompactTableCell>{formatPaciente(paciente)}</CompactTableCell>
      <CompactTableCell>{edad}</CompactTableCell>
      <CompactTableCell>{paciente.sexo}</CompactTableCell>
      <CompactTableCell>{formatDireccion(paciente.direccion)}</CompactTableCell>
      <CompactTableCell>{paciente.telefono1}</CompactTableCell>
      <CompactTableCell>{paciente.telefono2}</CompactTableCell>
      <CompactTableCell>{paciente.sistemaSalud?.nombre}</CompactTableCell>
      <CompactTableCell>{formatDate(paciente.fechaIngreso)}</CompactTableCell>
      <CompactTableCell>{formatDate(paciente.fechaModificacion)}</CompactTableCell>
      {/* <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/pacientes/${paciente.id}`) }
            ]}
          />
        )}
      </CompactTableCell> */}
    </TableRow>
  )
}

export default hasPermission([PERMISO.PacientesVer])(ListadoPacientes);
