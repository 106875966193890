import React, { useEffect, useRef } from 'react';
import ChartJS from 'chart.js';

export function Chart({ type, data, options, ...props }) {
  const chartRef = useRef();

  useEffect(() => {
    const myChartRef = chartRef.current.getContext('2d');

    new ChartJS(myChartRef, { type, data, options })
  }, []);

  return (
    <div {...props}>
      <canvas ref={chartRef} />
    </div>
  )
}
