import React from 'react';
import { Router } from '@reach/router';

import ListadoMuestrasBac from './ListadoMuestrasBac';
import CrearMuestraBac from './CrearMuestraBac';
import EditarMuestraBac from './EditarMuestraBac';
import NotFoundPage from '../NotFoundPage';

export default function PacientePages() {
  return (
    <Router>
      <ListadoMuestrasBac path="/" />
      <CrearMuestraBac path="/nuevo" />
      <EditarMuestraBac path="/:id" />
      <NotFoundPage default />
    </Router>
  )
}