import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { IconButton, makeStyles, Tooltip, Menu, MenuItem } from '@material-ui/core';
import { Avatar } from 'components/ui';
import { AuthContext } from 'auth';

function UserMenu({ usuario }) {
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = 'primary-account-menu';
  const isMenuOpen = Boolean(anchorEl);
  const esAdmin = user.rol === 'Administrador';

  const handleMenuOpen = event => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
    
  const handleEditarPerfil = () => {
    handleMenuClose();
    navigate('/editar-perfil');
  }

  const handleLogout = async () => {
    handleMenuClose();
    logout();
  }

  const handleForceAccess = () => {
    handleMenuClose();
    navigate('/login');
  }
  
  return (
    <React.Fragment>
      <Tooltip title={`${usuario.nombres} ${usuario.apellidos}`} placement="left">
        <IconButton
          edge="end"
          className={classes.button}
          aria-label="cuenta de usuario"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
        >
          <Avatar usuario={usuario} size="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >        
        <MenuItem onClick={handleEditarPerfil}>Editar perfil</MenuItem>
        {esAdmin && <MenuItem onClick={handleForceAccess}>Forzar Acceso</MenuItem>}
        <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

UserMenu.propTypes = {
  usuario: PropTypes.object.isRequired,
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    marginRight: theme.spacing(3),
  }
}))

export default UserMenu;