import React, { useState } from 'react';
import { Button, TableRow, TableCell, Box } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { truncate } from 'lodash';
import { DataTable, Loader, CompactTableCell, ActionsButton } from 'components/ui';
import SeguimientoModal from 'components/ficha/SeguimientoModal';
import { useMutation, useQuery } from 'api';
import { formatDate, formatDireccion, formatList } from 'helpers/format';
import { PERMISO, TIPOS_SEGUIMIENTOS } from '../../constants';

function ListadoSeguimientos({ ficha, refetch: refetchFicha }) {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  
  const canEdit = auth.hasPermission([PERMISO.SeguimientosModificar]);
  const fallecido = ficha.seguimientos.some(seg => seg.estadoPaciente?.nombre === 'Fallecido');

  const { data: seguimientos, loading, refetch: refetchSeguimiento } = useQuery(`/fichas/${ficha.id}/seguimientos`);
  const [crearSeguimiento] = useMutation(`/fichas/${ficha.id}/seguimientos`, { method: 'post' })
  const [editarSeguimiento] = useMutation(`/fichas/${ficha.id}/seguimientos/${modalData?.id}`, { method: 'patch' })

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetchSeguimiento({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });

  const openModal = (seguimiento) => {
    setModalData(seguimiento);
    setModalOpen(true);
  }
  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  }

  const handleSeguimientoSubmit = async values => {
    let resp;
    if (modalData) {
      resp = await editarSeguimiento({ body: values });
    } else {
      resp = await crearSeguimiento({ body: values });
    }
    console.log(resp);
  
    if (!resp.error) {
      refetchSeguimiento();
      refetchFicha();
      closeModal();
    }
  }
  
  return (
    <div>
      <Loader loading={loading} />

      <DataTable
        data={seguimientos}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <SeguimientoHeader />}
        renderRow={(seg) => (
          <SeguimientoRow
            key={seg.id}
            ficha={ficha}
            seguimiento={seg}
            canEdit={canEdit}
            onEdit={() => openModal(seg)}
          />
        )}
      />

      {canEdit && !fallecido && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            align="right"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => openModal(null)}
          >
            Agregar
          </Button>
        </Box>
      )}

      <SeguimientoModal
        ficha={ficha}
        seguimiento={modalData}
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleSeguimientoSubmit}
      />

    </div>
  );
}

function SeguimientoHeader() {
  return (
    <TableRow>
      <TableCell>#</TableCell>
      <TableCell>Fecha / Hora</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Detalle</TableCell>
      <TableCell>Cumple<br/>Cuarentena</TableCell>
      <TableCell>Estado</TableCell>
      <TableCell>Síntomas</TableCell>
      <TableCell>Extensión Cuarentena</TableCell>
      <TableCell>Dirección Cuarentena</TableCell>
      <TableCell>Centro Asistencial</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function SeguimientoRow({ ficha, seguimiento, canEdit, onEdit }) {
  let direccion;
  if (seguimiento.centroAsistencial) {
    direccion = seguimiento.centroAsistencial.direccion;
  } else if (seguimiento.residenciaCuarentena) {
    direccion = seguimiento.residenciaCuarentena.direccion;
  } else {
    direccion = seguimiento.direccionCuarentena;
  }

  return (
    <TableRow>
      <CompactTableCell>{seguimiento.id}</CompactTableCell>
      <CompactTableCell>{formatDate(seguimiento.fechaSeguimiento, 'DD/MM/YYYY HH:mm')}</CompactTableCell>
      <CompactTableCell>{TIPOS_SEGUIMIENTOS[seguimiento.tipo]}</CompactTableCell>
      <CompactTableCell>{truncate(seguimiento.detalle, { length: 100 })}</CompactTableCell>
      <CompactTableCell>{seguimiento.cumpleCuarentena ? 'S' : 'N'}</CompactTableCell>
      <CompactTableCell>{seguimiento.estadoPaciente?.nombre}</CompactTableCell>
      <CompactTableCell>{formatList(seguimiento.sintomas)}</CompactTableCell>
      <CompactTableCell>{seguimiento.fechaExtensionCuarentena ? formatDate(seguimiento.fechaExtensionCuarentena) : ''}</CompactTableCell>
      <CompactTableCell>{formatDireccion(direccion)}</CompactTableCell>
      <CompactTableCell>{seguimiento.centroAsistencial?.nombre}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: onEdit }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.SeguimientosVer])(ListadoSeguimientos);
