import React, { useState } from 'react'
import { TableCell, TableSortLabel, makeStyles } from '@material-ui/core'

export function useTableSort() {
  const [field, setField] = useState('');
  const [criteria, setCriteria] = useState('');

  const onChange = (newField) => {
    if (newField === field) {
      setCriteria(criteria === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setField(newField);
      setCriteria('ASC');
    }
  }

  return [{ field, criteria }, onChange];
}

export function SortableTableCell({ id, label, numeric, order, onOrderChange, ...props }) {
  const classes = useStyles();
  const orderActive = order && order.field === id;
  const orderDirection = order && order.criteria.toLowerCase();
  
  return (
    <TableCell
      {...props}
      align={numeric ? 'right' : 'left'}
      sortDirection={orderActive ? orderDirection : false}
    >
      <TableSortLabel
        active={orderActive}
        direction={orderActive ? orderDirection.toLowerCase() : 'asc'}
        onClick={() => onOrderChange(id)}
      >
        {label}
        {orderActive ? (
          <span className={classes.visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  )
}

const useStyles = makeStyles(theme => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))