import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import UsuarioForm from 'components/usuario/UsuarioForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearUsuario() {
  const [crearUsuario, { loading, error }] = useMutation('/usuarios', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearUsuario({ body: values });

    if (!error) {
      navigate('/admin/funcionarios');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'usuarios', title: 'Funcionarios', to: '/admin/funcionarios' },
        { key: 'actual', title: 'Nuevo funcionario', to: '/admin/funcionarios/nuevo', active: true },
      ]} />
      <Title>Nuevo Funcionario</Title>
      <Loader loading={loading} />

      <UsuarioForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.UsuariosModificar])(CrearUsuario);
