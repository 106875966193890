import React, { useState } from 'react';
import moment from 'moment';
import { Grid, TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon, Edit, PersonAdd } from '@material-ui/icons';
import { Snackbar } from 'components/ui';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, FilterGrid, TextField, CompactTableCell, Breadcrumbs, Title, useDelayedSearch, ActionsButton, Link } from 'components/ui';
import { useMutation, useQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatPaciente } from 'helpers/format';
import { navigate } from '@reach/router';
import { ESTADOS, PERMISO } from '../../constants';

function ListadoMuestrasBac() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10, busqueda: '' });
  const [snackOpen, setSnackOpen] = useState(false);
  
  const canEdit = auth.hasPermission([PERMISO.MuestrasBACModificar]);

  const { data: muestras, loading, refetch } = useQuery('/muestras-bac');
  const [migrarMuestraBac, { loading: posting, error }] = useMutation('/fichas/migrar-muestra-bac', { method: 'POST' });
    
  const search = useDelayedSearch(newState => refetch({ query: newState }));

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  const onChangeBusqueda = (e) => {
    const newState = { ...state, busqueda: e.target.value, page: 1 };
    setState(newState);
    search(newState);
  }

  const handleMigrar = async muestra => {
    const { error } = await migrarMuestraBac({ body: { idMuestra: muestra.id } });

    setSnackOpen(true);
    
    if (!error) {
      refetch({ query: state });
    }
  }
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'muestras', title: 'Muestras BAC', to: '/admin/muestras-bac', active: true },
      ]} />
      <Title>Muestas BAC</Title>
      <Loader loading={loading || posting} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fluid
            name='busqueda'
            label='Buscar'
            value={state.busqueda}
            onChange={onChangeBusqueda}
          />
        </Grid>
      </FilterGrid>

      <DataTable
        data={muestras}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <MuestraHeader />}
        renderRow={(muestra) => (
          <MuestraRow
            key={muestra.id}
            muestra={muestra}
            canEdit={canEdit}
            onMigrar={() => handleMigrar(muestra)}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/muestras-bac/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

      {snackOpen && <Snackbar
        message={!!error ? error.message : "Paciente registrado"}
        color={!!error ? "error" : "success"}
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

function MuestraHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Nombre Completo</TableCell>
      <TableCell>Edad</TableCell>
      <TableCell>Sexo</TableCell>
      <TableCell>Tel</TableCell>
      <TableCell>Comuna</TableCell>
      <TableCell>Tipo Muestra</TableCell>
      <TableCell>Fecha Muestra</TableCell>
      <TableCell>Resultado</TableCell>
      <TableCell>Criterio</TableCell>
      <TableCell>Estrategia</TableCell>
      <TableCell>Ficha #</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function MuestraRow({ muestra, canEdit, onMigrar }) {
  const edad = muestra.fechaNacimiento ? moment().diff(moment(muestra.fechaNacimiento), 'years') : '';
  const nombreComuna = muestra.direccion?.sector?.comuna?.nombre;

  const items = [{ key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/muestras-bac/${muestra.id}`) }];

  if (muestra.estado === 2 && nombreComuna === 'Lautaro' && !muestra.idFichaAsociada) {
    items.push({ key: 'migrar', text: 'Registrar como paciente', icon: <PersonAdd />, onClick: onMigrar });
  }

  return (
    <TableRow>
      <CompactTableCell>{muestra.id}</CompactTableCell>
      <CompactTableCell>{formatRut(muestra.rut)}</CompactTableCell>
      <CompactTableCell>{formatPaciente(muestra)}</CompactTableCell>
      <CompactTableCell>{edad}</CompactTableCell>
      <CompactTableCell>{muestra.sexo}</CompactTableCell>
      <CompactTableCell>{muestra.telefono1}</CompactTableCell>
      <CompactTableCell>{nombreComuna}</CompactTableCell>
      <CompactTableCell>{muestra.tipoMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>{formatDate(muestra.fechaMuestra)}</CompactTableCell>
      <CompactTableCell>{ESTADOS[muestra.estado]}</CompactTableCell>
      <CompactTableCell>{muestra.criterioMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>{muestra.estrategiaMuestra?.nombre}</CompactTableCell>
      <CompactTableCell>
        {muestra.fichaAsociada ? (
          <Link to={`/admin/pacientes/${muestra.fichaAsociada.idPaciente}/fichas/${muestra.fichaAsociada.id}`}>{muestra.idFichaAsociada}</Link>
        ): null}
      </CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton items={items} />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.MuestrasBACVer])(ListadoMuestrasBac);
