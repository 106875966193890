import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import { useMutation } from 'api';

export default function CreateAdmin({ title, listTitle, routePath, apiPath, FormComponent }) {
  const [createFn, { loading, error }] = useMutation(apiPath, { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await createFn({ body: values });

    if (!error) {
      navigate(routePath);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: listTitle, to: routePath },
        { key: 'actual', title, to: `${routePath}/nuevo`, active: true },
      ]} />
      <Title>{title}</Title>
      <Loader loading={loading} />

      <FormComponent
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
