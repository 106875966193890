import React from 'react';
import Admin from 'components/admin';
import ResidenciaSanitariaForm from 'components/residenciaSanitaria/ResidenciaSanitariaForm';
import { formatDireccion } from 'helpers';
import { PERMISO } from '../../constants';

export default function ResidenciaSanitariaPages() {
  return (
    <Admin
      createTitle="Nueva Residencia Sanitaria"
      listTitle="Residencias Sanitarias"
      editTitle="Editar Residencia"
      routePath="/admin/residencias-sanitarias"
      apiPath="/residencias-sanitarias"
      FormComponent={ResidenciaSanitariaForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ResidenciaSanitariaVer]}
      modifyPerms={[PERMISO.ResidenciaSanitariaModificar]}
      headerItems={['ID', 'Nombre', 'Dirección', 'Teléfono']}
      rowItems={row => [row.id, row.nombre, formatDireccion(row.direccion), row.telefono]}
    />
  )
}
