import React from 'react';
import { Typography, Container, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useMutation } from 'api';
import { isAuthenticated } from 'auth';
import { Loader, Formik, Form, ApiErrorMessage, Logo } from 'components/ui';
import { validatePassword } from '../helpers';

function CambiarClavePage({ navigate }) {
  const classes = useStyles();
  const [cambiarClave, { loading: posting, error }] = useMutation('/usuarios/cambiar-clave', { method: 'patch' });
  
  const validarClaves = (values) => {
    const errors = {};
    
    const errClave = validatePassword(values.claveNueva, values.claveActual);
    if (errClave)
      errors.claveNueva = errClave;
    
    return errors;
  }

  const handleCambioClave = async (values) => {
    const { error } = await cambiarClave({ body: values })

    if (!error) {
      navigate('/');
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay}>
        <Logo className={classes.logo} />
        <Container component="main" maxWidth="xs" className={classes.container}>
          <Typography variant="h5" align="center">Cambiar contraseña</Typography>
          <Loader loading={posting} />

          <Alert severity="warning">Antes de continuar debe cambiar su contraseña</Alert>

          <Formik
            initialValues={{ claveActual: '', claveNueva: '' }}
            onSubmit={handleCambioClave}
            validate={validarClaves}
          >
            <Form>
              <Form.Field xs={12}>
                <Form.PasswordField
                  name="claveActual"
                  label="Contraseña actual"
                  fluid
                />
              </Form.Field>
              <Form.Field xs={12}>
                <Form.PasswordField
                  name="claveNueva"
                  label="Contraseña nueva"
                  required
                  fluid
                />
              </Form.Field>
              <Form.Field>
                <Form.Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                >
                  Cambiar
                </Form.Button>
              </Form.Field>
              <Form.Field xs={12}>
                {error && <ApiErrorMessage error={error} />}
              </Form.Field>
            </Form>
          </Formik>

        </Container>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: '#c2e4f4',
    height: '120vh',
    textAlign: 'center',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '120vh',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    paddingTop: theme.spacing(2),
  },
  logo: {
    height: 140,
    margin: theme.spacing(4),
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}));


export default isAuthenticated(CambiarClavePage);