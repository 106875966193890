import { useState } from 'react';

export function useDelayedSearch(search, delay = 1000) {
  const [timer, setTimer] = useState(null);

  const onChange = (value) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => search(value), delay));
  }

  return onChange;
}
