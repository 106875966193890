import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useLazyQuery, useQuery } from 'api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import { Formik, Form, ApiErrorMessage } from 'components/ui';
import { formatResidenciaSanitaria, formatUsuario, validateLength } from 'helpers';
import DireccionForm from 'components/direccion/DireccionForm';

const getInitialValues = ({ ficha, seguimiento: seg }) => ({
  ficha: seg ? seg.ficha : ficha,
  fechaSeguimiento: seg ? seg.fechaSeguimiento : moment().toISOString(),
  usuario: seg ? seg.usuarioEncargado : null,
  tipo: seg ? seg.tipo.toString() : '1',
  detalle: seg ? seg.detalle : '',
  prescripcion: seg ? seg.prescripcion : '',
  fechaExtensionCuarentena: seg ? seg.fechaExtensionCuarentena : '',
  observaciones: seg ? seg.observaciones : '',
  direccionCuarentena: getInitialValuesDireccion(seg?.direccionCuarentena),
  residenciaCuarentena: seg ? seg.residenciaCuarentena : null,
  tipoCuarentena: seg?.residenciaCuarentena ? '2' : (seg?.idDireccionCuarentena === ficha.paciente?.idDireccion ? '1' : '3'),
  centroAsistencial: seg?.centroAsistencial || null,
  idEstadoPaciente: seg?.estadoPaciente?.id || '',
  cumpleCuarentena: seg ? seg.cumpleCuarentena : false,
  sintomas: seg ? seg.sintomas : [],
  idTipoMuestra: seg ? seg.muestras[0]?.idTipoMuestra : '',
})

const getInitialValuesDireccion = (direccion) => ({
  idTipo: direccion?.idTipo.toString() || '1',
  calle: direccion?.calle || '',
  numero: direccion?.numero || '',
  villa: direccion?.villa || '',
  otro: direccion?.otro || '',
  comuna: direccion?.sector.comuna || null,
  sector: direccion?.sector || null,
  idSector: direccion?.sector.id || '',
})

function SeguimientoModal ({ open, onClose, ficha, seguimiento: seg, error, onSubmit }) {
  const [buscarUsuarios] = useLazyQuery('/usuarios');
  const [buscarResidencias] = useLazyQuery('/residencias-sanitarias');
  const [buscarCentroAsistencial] = useLazyQuery('/centros-asistenciales');
  const { data: sintomas } = useQuery('/sintomas', {}, []);
  const { data: estadosPaciente } = useQuery('/estados-paciente', {}, []);
  const { data: tiposMuestra } = useQuery('/tipos-muestra', {}, []);

  const initialValues = getInitialValues({ ficha, seguimiento: seg });

  const handleFormSubmit = async (input, formik) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { ficha, usuario, residenciaCuarentena, centroAsistencial, sintomas, ...seguimientoValues } = values;
    seguimientoValues.idFicha = ficha.id;
    seguimientoValues.detalle = values.detalle || '';
    seguimientoValues.prescripcion = values.prescripcion || ' ';
    seguimientoValues.idUsuarioEncargado = values.usuario.id;
    seguimientoValues.idResidenciaCuarentena = residenciaCuarentena?.id;
    seguimientoValues.idCentroAsistencial = centroAsistencial?.id;
    if (sintomas) {
      seguimientoValues.sintomas = sintomas.map(s => s.id);
    }
    
    await onSubmit(seguimientoValues);
    formik.resetForm();
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm(ficha, estadosPaciente)}
    >
      {({ values, setFieldValue, setFieldTouched, handleSubmit }) => {
        const estadoPaciente = estadosPaciente.find(ep => ep.id === values.idEstadoPaciente);
        const internado = estadoPaciente?.nombre === 'Internado';
        const fallecido = estadoPaciente?.nombre === 'Fallecido';

        return (
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            maxWidth="xl"
          >
            <DialogTitle>{seg ? 'Editar Seguimiento' : 'Nuevo Seguimiento'}</DialogTitle>
            <DialogContent dividers>
              <Form alignItems="center">
                <FormUpdater ficha={ficha} seguimiento={seg} />
                <Form.Field xs={12} md={3}>
                  <Form.Lookup
                    required
                    name="usuario"
                    label="Funcionario"
                    fluid
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option ? formatUsuario(option) : ''}
                    fetcher={(busqueda) => buscarUsuarios({ query: { busqueda }}).then(resp => resp.data.nodes)}
                  />
                </Form.Field>
                <Form.Field xs={12} md={3}>
                  <Form.DateTime
                    required
                    name="fechaSeguimiento"
                    label="Fecha"
                    disableFuture
                    format="DD/MM/YYYY HH:mm"
                    fluid
                    InputLabelProps={{ shrink: true }}
                  />
                </Form.Field>
                <Form.Field xs={12} md={3}>
                  <Form.Select required name="tipo" label="Tipo" fluid align="left">
                    <MenuItem value="1">Visita presencial</MenuItem>
                    <MenuItem value="2">Llamado telefónico</MenuItem>
                    <MenuItem value="3">Mensaje</MenuItem>
                    <MenuItem value="4">Correo electrónico</MenuItem>
                  </Form.Select>
                </Form.Field>
                <Form.Field xs={12} md={3}>
                  <Form.Checkbox name="cumpleCuarentena" label="Cumple Cuarentena" />
                </Form.Field>

                <Form.Field xs={12} md={6}>
                  <Form.TextField name="detalle" label="Detalle" fluid validate={validateLength(0, 500)} />
                </Form.Field>
                <Form.Field xs={12} md={6}>
                  <Form.TextField name="prescripcion" label="Prescripción" fluid validate={validateLength(0, 500)} disabled={fallecido} />
                </Form.Field>

                <Form.Field xs={12} md={6}>
                  <Form.Autocomplete
                    name="sintomas"
                    label="Síntomas / Signos"
                    multiple
                    options={sintomas}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option ? option.nombre : ''}
                    filterSelectedOptions
                    fluid
                  />
                </Form.Field>
                <Form.Field xs={12} md={2}>
                  <Form.Select name="idEstadoPaciente" label="Estado Paciente" fluid align="left">
                    {estadosPaciente.map(ep => <MenuItem key={ep.id} value={ep.id}>{ep.nombre}</MenuItem>)}
                  </Form.Select>
                </Form.Field>
                <Form.Field xs={12} md={2}>
                  <Form.Datepicker name="fechaExtensionCuarentena" label="Extensión Cuarentena" fluid InputLabelProps={{ shrink: true }} disabled={fallecido} />
                </Form.Field>
                <Form.Field xs={12} md={2}>
                  <Form.Select name="idTipoMuestra" label="Solicitar Muestra" fluid align="left">
                    <MenuItem value="">Ninguna</MenuItem>
                    {tiposMuestra.map(tm => <MenuItem key={tm.id} value={tm.id}>{tm.nombre}</MenuItem>)}
                  </Form.Select>
                </Form.Field>

                {internado ? (
                  <Form.Field xs={12} md={6}>
                    <Form.Lookup
                      required
                      name="centroAsistencial"
                      label="Centro Asistencial"
                      fluid
                      getOptionSelected={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option ? option.nombre : ''}
                      fetcher={(busqueda) => buscarCentroAsistencial({ query: { busqueda }}).then(resp => resp.data)}
                    />
                  </Form.Field>
                ) : (
                  (!fallecido) ? (
                    <>
                      <Form.Field xs={12} md={2}>
                        <Form.Select
                          name="tipoCuarentena"
                          label="Lugar Cuarentena"
                          fluid
                          align="left"
                          onChange={tipo => {
                            if (tipo === '1') {
                              setFieldValue('direccionCuarentena', getInitialValuesDireccion(ficha.paciente.direccion));
                              setFieldTouched('direccionCuarentena');
                            }
                          }}
                        >
                          <MenuItem value="1">Domicilio del paciente</MenuItem>
                          <MenuItem value="2">Residencia sanitaria</MenuItem>
                          <MenuItem value="3">Nueva dirección</MenuItem>
                        </Form.Select>
                      </Form.Field>
                      {/* <Form.Field xs={12} md={6}></Form.Field> */}
              
                      {values.tipoCuarentena === '2' ? (
                        <Form.Field xs={12} md={6}>
                          <Form.Lookup
                            required
                            name="residenciaCuarentena"
                            label="Residencia Sanitaria"
                            fluid
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => option ? formatResidenciaSanitaria(option) : ''}
                            fetcher={(busqueda) => buscarResidencias({ query: { busqueda }}).then(resp => resp.data)}
                          />
                        </Form.Field>
                      ) : values.tipoCuarentena ? (
                        <DireccionForm values={values.direccionCuarentena} path="direccionCuarentena" />
                      ) : null}
                    </>
                  ) : null
                )}

                <Form.Field xs={12}>
                  <Form.TextField name="observaciones" label="Observaciones" fluid validate={validateLength(0, 500)} />
                </Form.Field>

                <Form.Field xs={12}>
                  {error && <ApiErrorMessage error={error} />}
                </Form.Field>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">Cancelar</Button>
              <Button onClick={handleSubmit} color="primary" autoFocus>Guardar</Button>
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}

function FormUpdater({ ficha, seguimiento }) {
  const { setValues } = useFormikContext();
  useEffect(() => {
    setValues(getInitialValues({ ficha, seguimiento }))
  }, [seguimiento]);

  return null;
}

const validateForm = (ficha, estadosPaciente) => (values) => {
  const errors = {};

  if (!values.fechaSeguimiento) {
    errors.fechaSeguimiento = 'La fecha es obligatoria';
  } else if (moment(values.fechaSeguimiento).isAfter()) {
    errors.fechaSeguimiento = 'La fecha no es válida';
  }
  if (!values.tipo) {
    errors.tipo = 'El tipo es obligatorio';
  }
  if (!values.usuario) {
    errors.usuario = 'El funcionario es obligatorio';
  }
  if (!values.idEstadoPaciente) {
    errors.idEstadoPaciente = 'El estado es obligatorio';
  } else {
    const estado = estadosPaciente.find(ep => ep.id === values.idEstadoPaciente);
    if (estado?.nombre === 'Internado' && !values.centroAsistencial) {
      errors.centroAsistencial = 'El centro asistencial es obligatorio';
    }
  }
  if (values.fechaExtensionCuarentena) {
    if (ficha.fechaFinCuarentena && moment(values.fechaExtensionCuarentena).isBefore(moment(ficha.fechaFinCuarentena))) {
      errors.fechaExtensionCuarentena = 'La fecha no es válida';
    }
    else if (moment(values.fechaExtensionCuarentena).isBefore()) {
      errors.fechaExtensionCuarentena = 'La fecha no es válida';
    }
    if (!values.observaciones) {
      errors.observaciones = 'Debe justificar la extensión de cuarentena';
    }
  }

  return errors;
}

SeguimientoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ficha: PropTypes.object.isRequired,
  seguimiento: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default SeguimientoModal;