import { createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#085735',
    },
    secondary: {
      main: '#e3b01f',
    },
    success: {
      main: '#64b98c',
    },
    disabled: {
      main: '#808080',
    }
  },
}, esES);

export default theme;
