import React from 'react';
import Admin from 'components/admin';
import EstrategiaMuestraForm from 'components/listados/EstrategiaMuestraForm';
import { PERMISO } from '../../constants';

export default function EstrategiaMuestraPages() {
  return (
    <Admin
      createTitle="Nueva Estrategia de Toma de Muestras"
      listTitle="Estrategias de Toma de Muestras"
      editTitle="Editar Estrategia de Toma de Muestras"
      routePath="/admin/estrategias-toma-muestras"
      apiPath="/estrategias-muestra"
      FormComponent={EstrategiaMuestraForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.ListadosVer]}
      modifyPerms={[PERMISO.ListadosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}
