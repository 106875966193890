import React from 'react';
import { useField } from 'formik';
import { DateTime as MuiDateTime } from '../DateTime';

export function DateTime(props) {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && !!meta.error;

  const handleDateChange = val => {
    helpers.setValue(val.toISOString());
  }

  return (
    // <TextField
    //   {...field}
    //   {...props}
    //   type="date"
    //   value={isoToLocal(field.value, props.format)}
    //   error={hasError}
    //   helperText={hasError && meta.error}
    //   onChange={handleChange}
    //   InputLabelProps={{ shrink: true }}
    // />
    <MuiDateTime
      {...field}
      {...props}
      variant="inline"
      onChange={handleDateChange}
      error={hasError}
      helperText={hasError && meta.error}
    />
  )
}
