import React from 'react';
import { styled, FormControl, TableCell, Grid, TextareaAutosize } from '@material-ui/core';

export const StyledFormControl = styled(({ fluid, ...other}) => <FormControl {...other} />)(({ theme, fluid }) => ({
  margin: theme.spacing(1),
  width: fluid ? '100%' : 200,
}));

export const CompactTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '0.8rem',
}));

export const FilterGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}))

export const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  display: 'block',
  width: '100%',
  fontSize: 14,
  padding: theme.spacing(2),
  minWidth: '100%',
  maxWidth: '100%'
}));
